import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  SanitasTitle as Title,
  SanitasInfoText as InfoText,
  Invoices,
  ZemplinSelect as Select,
  SanitasOrders as Orders,
  SanitasPaginationSimple as PaginationSimple,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  onOrderAgain: (publicId: string) => void;
}

function MyOrders({
  currentId,
  orders,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  onOrderAgain,
}: Props) {
  const { limit, offset, total } = ordersData;

  return (
    <Wrapper>
      {/* faza 2 - uprava API + napojenie na frontende */}
      {false && (
        <>
          <Title marginTop={0} marginBottom={32}>
            {__('Neuhradené faktúry')}
          </Title>
          <InfoText
            type={'ERROR'}
            info={'Máte 1 neuhradenú faktúru po splatnosti.'}
          />
          <Invoices isFetching={false} invoices={invoices} />
        </>
      )}

      <Title marginTop={0} marginBottom={24}>
        {__('Moje objednávky')}
      </Title>
      {options && options.length > 1 && (
        <Select
          value={currentId}
          options={options}
          onChange={handleSelectChange}
        />
      )}
      <Orders
        isFetching={isFetchingOrders}
        orders={orders}
        onOrderAgain={onOrderAgain}
      />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  /* max-width: ${rem(1048)}; */
  margin-right: 0;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${rem(1)} solid #C2CED9;
  box-shadow: 0px ${rem(2)} ${rem(8)} #4D617C14;
  border-radius: ${rem(8)};
  padding: ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;
export default MyOrders;
