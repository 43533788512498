import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import { customerInfoSelector, fetchCustomerDetails } from './myAccountSlice';
import MyInfo from '../../components/MyAccount/MyInfo';
import API, { ThenArg } from '../../services/API';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

interface Props {
  user: any;
  dispatch: any;
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
}

class MyInfoContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_INFO));
      await dispatch(fetchCustomerDetails());
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const { customerInfo, user } = this.props;
    if (!customerInfo) {
      return null;
    }

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Moje údaje' }} />
        <MyInfo
          customerInfo={customerInfo}
          userId={user && user.id}
          dispatchProp={this.props.dispatch}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    customerInfo: customerInfoSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyInfoContainer' })(MyInfoContainer),
);
