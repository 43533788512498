import * as React from 'react';
import styled, { css } from 'styled-components';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { getImagePath } from '../../../utilities/product';
import { Link } from 'react-router';
import { rem } from 'polished';
import {
  ProductTabSmall,
  NoItemsWrapper,
  FlexRow,
  SanitasProductTab as ProductTabDefault,
  SanitasProductTabRow as ProductTabRow,
} from 'eshop-defaults';
import { prop, round } from '../../../utilities';
import { __ } from 'react-i18n';
import { resolveCurrencySignFromCurrency } from '../../../configureTrans';

const BtnBack = styled(ButtonBack)`
  background-image: url('/images/blog_assets/arrow_back_white.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: none;
  border: none;
  ${(props: any) => {
    const { theme, type } = props;
    switch (type) {
      case 'add-to-cart-popup': {
        return css`
          position: absolute;
          height: 64px;
          width: 32px;
          background-color: #2cc5b7;
          border-radius: 4px;
          z-index: 500;
          left: -42px;
          top: 99px;

          visibility: ${({ type }) =>
            type === 'product-detail' ? 'hidden' : 'visible'};

          @media only screen and (max-width: 580px) {
            visibility: hidden;
            z-index: -10;
          }

          @media only screen and (max-width: 520px) {
            visibility: hidden;
            z-index: -10;
          }
        `;
      }
      case 'product': {
        return css`
          position: absolute;
          height: 35px;
          width: 35px;
          background-color: #3962a8;
          z-index: 500;
          left: -15px;
          top: 45%;
          border-radius: 50%;
          visibility: ${({ type }) =>
            type === 'product-detail' ? 'hidden' : 'visible'};

          &:disabled {
            //background-image: url('/images/blog_assets/arrow_back_gray.svg');
            cursor: unset;
          }
        `;
      }
      default: {
        return css``;
      }
    }
  }};
`;

const BtnNext = styled(ButtonNext)`
  background-image: url('/images/blog_assets/right-arrow-white.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  ${(props: any) => {
    const { theme, type } = props;
    switch (type) {
      case 'add-to-cart-popup': {
        return css`
          position: absolute;
          height: 64px;
          width: 32px;
          background-color: #2cc5b7;
          border-radius: 4px;
          border: none;
          z-index: 500;
          right: -42px;
          top: 99px;

          visibility: ${({ type }) =>
            type === 'product-detail' ? 'hidden' : 'visible'};

          @media only screen and (max-width: 580px) {
            visibility: hidden;
            z-index: -10;
          }

          @media only screen and (max-width: 520px) {
            visibility: hidden;
            z-index: -10;
          }
        `;
      }
      case 'product': {
        return css`
          position: absolute;
          height: 35px;
          width: 35px;
          background-color: #3962a8;
          border: none;
          z-index: 500;
          right: -15px;
          top: 45%;
          border-radius: 50%;
          visibility: ${({ type }) =>
            type === 'product-detail' ? 'hidden' : 'visible'};

          &:disabled {
            //background-image: url('/images/blog_assets/right-arrow-gray.svg');
            cursor: unset;
          }
        `;
      }
      default: {
        return css``;
      }
    }
  }};
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  overflow: visible;
`;

const DotComponent = styled(Dot)`
  width: 8px;
  height: 8px;
  border: none;
  background-color: #dddddd;
  padding: 0;
  margin: 0 4px;
  border-radius: 50%;

  &:disabled {
    background-color: #ffd607;
  }
`;

const Dots = styled.div`
  width: 100%;
  margin: auto;
  margin-top: -20px;
  text-align: center;
  position: absolute;
  @media only screen and (max-width: 1130px) {
    margin-top: -35px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 620px) {
    margin-top: -10px;
  }
  @media only screen and (max-width: 580px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 520px) {
    margin-top: -2vw;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 0;
  }
  @media only screen and (max-width: 380px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-220px' : 'initial')};
  }
  @media only screen and (max-width: 340px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-200px' : 'initial')};
  }
`;

const PhotogalleryItem = styled.div`
  margin: auto;
  width: 100%;
  height: 305px;
  line-height: 20px;
  display: flex;

  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 255px;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 210px;
  }
`;

const PhotogalleryCover = styled.img`
  margin: auto;
  width: 100%;
  height: 305px;
  border: 1px solid #dddddd;
  padding: ${rem(16)};

  @media only screen and (max-width: 900px) {
    width: auto;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 255px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 210px;
  }
`;

const ProductItem = styled(Link)`
  text-decoration: none;
  /* margin: auto; */
  width: 228px;
  height: 231px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1130px) {
    margin: auto;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 230px;
  }
`;

const ProductThumb = styled.img`
  margin: auto;
  width: 91px;
  height: 120px;
  max-height: 120px;
  border: 1px solid #dddddd;
`;

const ProductName = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 24px;
  width: 85%;
  color: #414141;
  font-weight: 500;
  line-height: 20px;
`;

const SliderCustom = styled(Slider)`
  ${(props: any) => {
    const { theme, type } = props;
    switch (type) {
      case 'add-to-cart-popup': {
        return css`
          height: ${rem(282)};
          border-radius: ${rem(8)};
          overflow: hidden;
          border: ${({ theme }) => theme.borders.productList};
        `;
      }
      case 'product': {
        return css`
          height: ${rem(430)};
          border: ${({ theme }) => theme.borders.productList};
          border-radius: ${rem(8)};
          ${({ theme }) => theme.mediab.s450`
          height: ${rem(364)};
          overflow: hidden;
        `}
        `;
      }
      default: {
        return css`
          height: ${rem(230)};
        `;
      }
    }
  }};
`;

const StyledButton = styled(Link)`
  width: ${rem(84)};
  height: ${rem(32)};
  background: #2cc5b7;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`;
const ItemPrice = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-family: 'Roboto', sans-serif;
  color: black;
  font-weight: 700;
`;
const ProductImg = styled.img`
  margin: auto;
  height: 120px;
  max-height: 120px;
  max-width: 120px;
`;
const Name = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  color: black;
  text-align: center;
  max-width: initial;
  margin: 0;
  padding: 0 ${rem(16)};
  display: flex;
  align-items: center;
  height: auto;
  margin-top: ${rem(4)};
  cursor: pointer;
`;

const ProductItemAddToCart = styled.div`
  text-decoration: none;
  /* margin: auto; */
  width: 197px;
  height: 282px;
  border: 1px solid #00346329;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${rem(16)};
`;

const ProductTabDefaultWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediab.s450`
    width: calc(100% + 1px);
  `}
`;

interface State {
  width: number;
  height: number;
  visibleSlides: number;
  type: string;
  slides: any;
}

export interface Props {
  type: string;
  slides: any;
  addToCart?: any;
  addToRequest?: any;
  user?: any;
  cartItemsIds?: any[];
  handleClose?: any;
  router?: any;
}

class SliderComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.state = {
      width: 210,
      height: 305,
      visibleSlides: 3,
      type: '',
      slides: [],
    };
  }

  public renderItem(key: number, img: string, item: any) {
    const { addToCart, addToRequest, type, user, cartItemsIds } = this.props;

    if (type === 'photogallery') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt="Obrázok vo fotogalerií"
            src={getImagePath(img, { width: 400, height: 300 })}
          />
        </PhotogalleryItem>
      );
    } else if (type === 'ebook') {
      const isInCart =
        cartItemsIds && cartItemsIds.includes(prop(item, 'main_good.good_id'));

      return (
        <ProductTabSmall
          key={item.product_id}
          // tslint:disable-next-line:jsx-no-lambda
          addToCart={() => addToCart(item)}
          // tslint:disable-next-line:jsx-no-lambda
          addToRequest={() => addToRequest(item)}
          product={item}
          user={user}
          isInCart={isInCart}
        />
      );
    } else if (this.props.type === 'product') {
      return (
        <ProductTabDefaultWrapper>
          {' '}
          <ProductTabRow
            isElasticCategory={false}
            key={item.product_id}
            product={item}
          />
        </ProductTabDefaultWrapper>
        // <ProductItem to={`/produkt/${item.id}/${item.url}`} key={item.id}>
        //   <ProductThumb
        //     alt={item.name}
        //     src={getImagePath(item.image, { width: 90, height: 120 })}
        //   />
        //   <ProductName>{item.name}</ProductName>
        // </ProductItem>
      );
    } else if (this.props.type === 'product-detail') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt={`Obrázok vo fotogalerií č. ${key}`}
            src={getImagePath(img, { width: 400, height: 300 })}
          />
        </PhotogalleryItem>
      );
    } else if (this.props.type === 'add-to-cart-popup') {
      const goodWithPrice = prop(item, 'goods', []).filter(
        good => good.unitprice > 0,
      );

      const handleOnClick = () => {
        this.props.handleClose();
        this.props.router.push(`/produkt/${item.product_id}/${item.url}`);
      };
      let price = prop(goodWithPrice, '0.unitprice', 0);
      if (typeof price === 'number') {
        price = round(price, 2).toFixed(2);
      }
      return (
        <ProductItemAddToCart key={item.id} className="test">
          <ProductImg
            alt={item.name}
            src={getImagePath(item.picture, { width: 90, height: 120 })}
          />
          <Name onClick={handleOnClick}>{item.name}</Name>
          <ItemPrice>
            {price}{' '}
            {resolveCurrencySignFromCurrency(
              prop(item, 'main_good.currency_id', 'EUR'),
            )}
          </ItemPrice>
          <StyledButton onClick={handleOnClick}>{__('Detail')} </StyledButton>
        </ProductItemAddToCart>
      );
    }
    return null;
  }

  public renderDots(items: any[], slide: number) {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(<DotComponent slide={i} />);
    }
    return table;
  }

  public updateState() {
    const win = window.innerWidth;
    if (
      this.props.type === 'photogallery' ||
      this.props.type === 'product-detail'
    ) {
      if (win < 580 && win >= 420) {
        this.setState({
          width: 400,
          height: 255,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 350,
          height: 210,
          visibleSlides: 1,
        });
      } else {
        this.setState({
          width: 300,
          height: 170,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'product') {
      if (win > 1130) {
        this.setState({
          width: 310,
          height: 400,
          visibleSlides: 3,
        });
      } else if (win <= 1130 && win >= 780) {
        this.setState({
          width: 310,
          height: 400,
          visibleSlides: 3,
        });
      } else if (win < 780 && win >= 680) {
        this.setState({
          width: 200,
          height: 400,
          visibleSlides: 2,
        });
      } else if (win < 680 && win >= 580) {
        this.setState({
          width: 200,
          height: 400,
          visibleSlides: 2,
        });
      } else if (win < 580 && win >= 550) {
        this.setState({
          width: 400,
          height: 400,
          visibleSlides: 1,
        });
      } else if (win < 550 && win >= 480) {
        this.setState({
          width: 400,
          height: 400,
          visibleSlides: 1,
        });
      } else if (win < 480 && win >= 440) {
        this.setState({
          width: 400,
          height: 400,
          visibleSlides: 1,
        });
      } else if (win < 440 && win >= 420) {
        this.setState({
          width: 400,
          height: 400,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 400,
          height: 296,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 400,
          height: 296,
          visibleSlides: 1,
        });
      } else {
        this.setState({
          width: 400,
          height: 296,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'ebook') {
      if (win > 750) {
        this.setState({
          width: 600,
          height: 192,
          visibleSlides: 2,
        });
      } else {
        this.setState({
          width: 300,
          height: 172,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'add-to-cart-popup') {
      if (win > 600) {
        this.setState({
          width: 596,
          height: 492,
          visibleSlides: 3,
        });
      } else {
        this.setState({
          width: 300,
          height: 172,
          visibleSlides: 1,
        });
      }
    }
  }

  public componentWillMount() {
    this.updateState();
  }
  public componentDidMount() {
    this.setState({
      type: this.props.type,
      slides: this.props.slides,
    });
    window.addEventListener('resize', this.updateState);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateState);
  }

  public render() {
    const { slides, type } = this.props;
    const { width, height, visibleSlides } = this.state;

    return (
      <>
        {slides && slides.length > 0 ? (
          <Carousel
            naturalSlideHeight={height}
            naturalSlideWidth={width}
            visibleSlides={visibleSlides}
            step={1}
            totalSlides={slides.length}
            isPlaying={type === 'add-to-cart-popup' || 'product' ? true : false}
          >
            {false && <BtnBack type={type} />}
            {type === 'add-to-cart-popup' || 'product' ? (
              <BtnBack type={type} />
            ) : null}

            <SliderCustom type={type}>
              {slides.map((item, index) => {
                return (
                  <Slide
                    innerClassName="sliderCentered"
                    index={index}
                    key={index}
                  >
                    {this.renderItem(index, item.img ? item.img : '', item)}
                  </Slide>
                );
              })}
            </SliderCustom>
            {false && (
              <Dots type={type}>{this.renderDots(slides, visibleSlides)}</Dots>
            )}
            {false && <BtnNext type={type} />}
            {type === 'add-to-cart-popup' || 'product' ? (
              <BtnNext type={type} />
            ) : null}
          </Carousel>
        ) : (
          <NoItemsWrapper text={'Nenašli sa žiadne produkty'} />
        )}
      </>
    );
  }
}

export default SliderComponent;
