import * as React from 'react';
import { __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  SearchForm,
  OutsideClick,
  SanitasMobileNavMenu as MobileNavMenu,
  prop,
  SanitasHeaderLogo,
  SanitasSearchForm,
  SanitasSvgIcon as SvgIcon,
  SanitasIconType as IconType,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { MobileNavigation } from './MobileNavigation';

import { CART_ROUTES } from '../../containers/Cart/utilities';
import HeaderWishlist from './HeaderWishlist';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
  resetSearchTerm: any;
  user: any;
  partialSearchCategoryProducts: any;
}

/*****************/

const StyledMobileWrapper = styled(MobileWrapper)`
  width: 100%;
  margin: 0 auto;
  box-shadow: ${({ theme }) => theme.boxShadow.header};
  background: ${({ theme }) => theme.colors.white};
`;

const HeaderWrapper = styled(OutsideClick)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }
`;

const MobileMenuWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  padding: ${rem(12)} ${rem(16)};
  height: initial;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const LeftWrapper = styled(RightWrapper)``;

const SearchIconWrapper = styled.div`
  svg {
    fill: ${({ theme }) => theme.colors.tertiary};
  }
`;

const MenuIconWrapper = styled.div`
  svg {
    fill: ${({ theme }) => theme.colors.tertiary};
  }
`;

function MobileHeader({
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  items,
  partialSearchProducts,
  partialSearchCategories,
  partialSearchProductsIsFetching,
  partialSearchCategoriesIsFetching,
  cartData,
  location,
  resetSearchTerm,
  user,
  partialSearchCategoryProducts,
}: Props & WithRouterProps) {
  const { items: cartItems, currency, total_items_price } =
    (cartData as any) || {};

  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [stickyHeaderShowed, setStickyHeaderShowed] = React.useState(false);

  const logoSrc = '/images/sanitaske/logo--subtitle.svg';
  const cartRoute = CART_ROUTES[prop(cartData, 'step', 1)];

  const searchWrapper = React.createRef<HTMLDivElement>();

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  // React.useEffect(() => {
  //   const node = searchWrapper.current;
  //   if (isSearchVisible && node) {
  //     const input = node?.querySelector('input');
  //     if (input) {
  //       input.focus();
  //     }
  //   }
  // }, [isSearchVisible, searchWrapper]);

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setIsMenuVisible(false);
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 100) {
      setStickyHeaderShowed(true);
    }
    if (window.pageYOffset < 25) {
      setStickyHeaderShowed(false);
    }
  };

  React.useEffect(() => {
    const { pathname } = location;
    if (!pathname.includes(__r('routes:vyhladavanie', 'vyhladavanie'))) {
      resetSearchTerm();
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, resetSearchTerm]);

  return (
    <StyledMobileWrapper>
      <HeaderWrapper handleFunction={hideSearchResultsViewer}>
        {!stickyHeaderShowed && (
          <>
            <HeaderProfile />
          </>
        )}
        <MobileMenuWrapper>
          <LeftWrapper>
            <MenuIconWrapper>
              <SvgIcon
                icon={IconType.listMenu}
                alt="Otvoriť menu"
                cursor={'pointer'}
                marginRight={16}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={toggleMenu}
              />
            </MenuIconWrapper>
            <SanitasHeaderLogo logo={logoSrc} />
          </LeftWrapper>
          <RightWrapper>
            <SearchIconWrapper>
              <SvgIcon
                icon={IconType.search}
                alt="Otvoriť vyhľadávanie"
                cursor={'pointer'}
                marginRight={16}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={toggleSearch}
              />
            </SearchIconWrapper>
            <HeaderWishlist mobile={true} />
            <HeaderCart
              cartRoute={cartRoute}
              numberOfProducts={cartItems ? cartItems.length : 0}
              totalPrice={total_items_price ? total_items_price : 0}
              currency={currency}
            />
          </RightWrapper>
        </MobileMenuWrapper>
        {isSearchVisible && (
          <SearchWrapper ref={searchWrapper}>
            <SanitasSearchForm
              user={user}
              searchResults={{
                productsResult: partialSearchProducts,
                categoriesResult: partialSearchCategories,
              }}
              partialSearchProductsIsFetching={partialSearchProductsIsFetching}
              partialSearchCategoriesIsFetching={
                partialSearchCategoriesIsFetching
              }
              redirectToSearchResultPage={redirectToSearchResultsPage}
              searchTerm={searchTerm}
              handleSearchTermChange={handleSearchTermChange}
              handleFocus={handleSearchFocus}
              hideResultViewer={hideSearchResultsViewer}
              resultsDropdownVisible={resultsDropdownVisible}
              isMobile={true}
              partialSearchCategoryProducts={partialSearchCategoryProducts}
            />
          </SearchWrapper>
        )}
        {isMenuVisible && items ? (
          <>
            <MobileNavMenu closeMenu={closeMenu}>
              <MobileNavigation
                closeMenu={closeMenu}
                items={items}
                location={location}
              />
            </MobileNavMenu>
            <Dimmer height={100} />
          </>
        ) : null}
      </HeaderWrapper>
    </StyledMobileWrapper>
  );
}

export default withRouter(MobileHeader);
