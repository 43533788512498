import { prop } from 'eshop-defaults/lib';
import { getImagePath, resolveProductName, resolveProductUrl } from './product';
import { stripHtmlTags } from '.';

export function isEmptyObj(obj) {
  if (obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return true;
}

export function getCmsMetaTags(content: any) {
  let metaTags: any = {};

  if (content && content.meta) {
    metaTags = content.meta;
  }
  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
    metaTags.og_title = content && content.name;
  }
  if (
    !metaTags ||
    !metaTags.og_description ||
    Object.keys(metaTags.og_description).length === 0
  ) {
    let desc = prop(content, 'meta.description')
      ? prop(content, 'meta.description')
      : prop(content, 'anotation');

    desc = stripHtmlTags(desc);

    if (desc) {
      metaTags.description = desc;
    }

    const ogDesc = prop(content, 'meta.og_description')
      ? prop(content, 'meta.og_description')
      : prop(content, 'anotation')
      ? prop(content, 'anotation')
      : desc;
    if (ogDesc) {
      metaTags.og_description = content && content.annotation;
    }
  }
  return metaTags;
}

export function getEbookMetaTags(ebook: any) {
  const content = prop(ebook, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getAuthorMetaTags(author: any) {
  const content = prop(author, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getProductMetaTags(product, defaultTitle?: string) {
  const productPublish = prop(product, 'publish', [])[0];

  let metaTags: any = {};
  if (productPublish && productPublish.content.id !== null) {
    if (metaTags && !metaTags.title) {
      metaTags.title = defaultTitle
        ? `${resolveProductName(product)} - ${defaultTitle}`
        : resolveProductName(product);
    }
  } else {
    metaTags.title = defaultTitle;
  }

  if (metaTags && !metaTags.og_description && product && product.publish) {
    metaTags.og_description =
      product && product.publish && product.publish.length > 0
        ? product.publish[0].product_description
        : '';
  }

  if (metaTags && !metaTags.description && product && product.publish) {
    metaTags.description =
      product && product.publish && product.publish.length > 0
        ? prop(product.publish[0], 'content.json_content.meta.description')
          ? prop(product.publish[0], 'content.json_content.meta.description')
          : product.publish[0].product_description
        : '';
  }

  if (metaTags && !metaTags.keywords && product && product.publish) {
    metaTags.keywords =
      product && product.publish && product.publish.length > 0
        ? prop(product.publish[0], 'content.json_content.meta.keywords')
        : '';
  }

  if (
    metaTags &&
    ((metaTags.og_image && !metaTags.og_image.length) || !metaTags.og_image) &&
    product &&
    product.picture
  ) {
    metaTags.og_image = product.picture
      ? encodeURI(
          `${process.env.REACT_APP_API_BASE_URL}/image?query=${product.picture}`,
        )
      : '';
    metaTags.og_image_width = metaTags.og_image_height = 1005;
  }

  if (metaTags && !metaTags.og_title) {
    metaTags.og_title = metaTags.title;
  }

  if (metaTags && !metaTags.og_type) {
    metaTags.og_type = 'website';
  }

  if (metaTags && !metaTags.og_url && product && product.url) {
    metaTags.og_url = resolveProductUrl(product);
  }

  return metaTags;
}

export function getCategoryMetaTags(data, defaultTitle?: string) {
  if (!data) {
    return {};
  }
  const content =
    prop(data, 'publish.0.content', null) || prop(data, 'content', null);
  const meta = prop(
    content && content.length > 0 ? content[0] : null,
    'json_content.meta',
    null,
  );

  return {
    title:
      prop(data, 'publish.0.category_name') ||
      prop(meta, 'title', `${data.category_name}`) ||
      prop(data, 'category_name', defaultTitle),
    description:
      prop(meta, 'description', null) || prop(data, 'category_descr'),
    keywords: prop(meta, 'keywords', null),
    og_article_author: prop(meta, 'og_article_author', null),
    og_article_section: prop(meta, 'og_article_section', null),
    og_article_tag: prop(meta, 'og_article_tag', null),
    og_description:
      prop(meta, 'og_description', null) || prop(meta, 'description', null),
    og_image:
      prop(meta, 'og_image', null) || prop(data, 'image')
        ? getImagePath(prop(data, 'image'))
        : '',
    og_title:
      prop(data, 'publish.0.category_name') ||
      prop(meta, 'og_title', null) ||
      prop(meta, 'title', `${data.category_name}`) ||
      prop(data, 'category_name'),
    og_type: prop(meta, 'og_type', 'website'),
    og_url: prop(meta, 'og_url', null) || prop(data, 'url'),
    og_image_width: 1005,
    og_image_height: 1005,
  };
}

export function getFooterArticleMetaTags(article) {
  console.log({ article });
  // const content = prop(category, 'content', {});
  const metaTags: any = {};
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (article && (!metaTags.title || isEmptyObj(metaTags.title))) {
    metaTags.title = article.name;
  }

  return metaTags;
}
