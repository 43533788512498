import { currencySelector } from './../App/selectors';
import {
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  REQUEST_HOME_NEW_PRODUCTS,
  RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  REQUEST_HOME_SALE_PRODUCTS,
  RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  REQUEST_HOME_BENEFITS,
  RECEIVE_HOME_BENEFITS_SUCCESS,
  RECEIVE_HOME_BENEFITS_ERROR,
  REQUEST_COMPANY_INFO,
  RECEIVE_COMPANY_INFO_SUCCESS,
  RECEIVE_COMPANY_INFO_ERROR,
} from './constants';
import { prop } from '../../utilities';
import { langSelector } from '../App/selectors';
import {
  firstHomeBenefitsSelector, // TODO remove
  newProductsSelector,
  saleProductsSelector,
  homeCompanyInfoSelector,
} from './selectors';
import { AppThunk } from '../../rootReducer';

const requestHomeNewProducts = () => ({
  type: REQUEST_HOME_NEW_PRODUCTS,
});

const receiveHomeNewProductsSuccess = products => ({
  type: RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveHomeNewProductsError = error => ({
  type: RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeNewProducts = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = newProductsSelector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        const lang = langSelector(state);
        const currency = currencySelector(getState());

        dispatch(requestHomeNewProducts());
        const products = await API.searchProducts(
          {
            limit: 6,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isNew: '1',
            withProductPackages: '1',
            goodsWithStores: '1',
            withProductLabels: '1',
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );
        dispatch(receiveHomeNewProductsSuccess(products));
      }
    } catch (e) {
      console.log({ error: e });
      dispatch(receiveHomeNewProductsError(e));
    }
  };
};

const requestHomeSaleProducts = () => ({
  type: REQUEST_HOME_SALE_PRODUCTS,
});

const receiveHomeSaleProductsSuccess = products => ({
  type: RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveHomeSaleProductsError = error => ({
  type: RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeSaleProducts = (categoryId: string): AppThunk => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const saleProducts = saleProductsSelector(state);

      if (
        !saleProducts ||
        !saleProducts.productsData ||
        !saleProducts.productsData[categoryId]
      ) {
        const lang = langSelector(state);
        const currency = currencySelector(getState());

        dispatch(requestHomeSaleProducts());
        const products = await API.searchProducts(
          {
            limit: 6,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isSale: '1',
            withProductPackages: '1',
            goodsWithStores: '1',
            withProductLabels: '1',
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );
        dispatch(receiveHomeSaleProductsSuccess(products));
      }
    } catch (e) {
      dispatch(receiveHomeSaleProductsError(e));
    }
  };
};

export const setHomeArticlesFilters = filters => ({
  type: SET_HOME_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeRecommended = () => ({
  type: REQUEST_HOME_RECOMMENDED,
});

const receiveHomeRecommendedSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_ERROR,
  payload: {
    error,
  },
});

const setHomeRecommendedFilter = filter => ({
  type: SET_HOME_RECOMMENDED_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeRecommended = filter => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeRecommended());
      if (filter && filter !== getState().homepage.recommended.filter) {
        dispatch(setHomeRecommendedFilter(filter));
      }
      const lang = langSelector(getState());

      let tree =
        getState().recommended &&
        getState().recommended.recommendedArticlesOptions;
      if (!tree) {
        const subtree = await API.loadSitemapSubtree(
          'ODPORUCANE',
          {},
          {
            xAcceptLanguage: lang,
          },
        );
        tree = prop(subtree, 'sitemap_tree', []);
        dispatch(loadHomeRecommendedOptions(tree));
      }
      const firstOptionId = tree && tree[0] && tree[0].id;

      const id = parseInt(filter, 10) || firstOptionId || 0;
      if (id) {
        const articles = await API.loadArticles(
          {
            sitemapId: id,
            limit: 5,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeRecommendedSuccess(articles));
      } else {
        dispatch(receiveHomeRecommendedSuccess([]));
      }
    } catch (e) {
      dispatch(receiveHomeRecommendedError(e));
    }
  };
};

const receiveHomeRecommendedOptionsSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedOptionsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedOptions = options => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(receiveHomeRecommendedOptionsSuccess(options));
    } catch (e) {
      dispatch(receiveHomeRecommendedOptionsError(e));
    }
  };
};

const requestHomeFastNews = () => ({
  type: REQUEST_HOME_FAST_NEWS,
});

const receiveHomeFastNewsSuccess = fastNews => ({
  type: RECEIVE_HOME_FAST_NEWS_SUCCESS,
  payload: {
    fastNews,
  },
});

const receiveHomeFastNewsError = error => ({
  type: RECEIVE_HOME_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFastNews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeFastNews());
      const lang = langSelector(getState());
      const articles = await API.loadArticles(
        {
          sitemapUniqueId: 'BLESKOVKY',
          limit: 3,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeFastNewsSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeFastNewsError(e));
    }
  };
};

const requestHomeTopArticle = () => ({
  type: REQUEST_HOME_TOP_ARTICLE,
});

const receiveHomeTopArticlesSuccess = topArticle => ({
  type: RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  payload: {
    topArticle,
  },
});

const receiveHomeTopArticleError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopArticle = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeTopArticle());
      const lang = langSelector(getState());
      const topArticle = await API.loadArticles(
        {
          isTop: '1',
          sfForm: 'Article',
          onlyActual: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeTopArticlesSuccess(topArticle));
    } catch (e) {
      dispatch(receiveHomeTopArticleError(e));
    }
  };
};

const receiveHomeBannerSuccess = banner => ({
  type: RECEIVE_HOME_BANNER_SUCCESS,
  payload: {
    banner,
  },
});

export const loadHomeBanner = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      const banners = await API.loadBanners(
        { position: 'HOMEPAGE' },
        { xAcceptLanguage: lang },
      );
      const banner = prop(banners, 'banners');
      if (banner && banner.length > 0) {
        dispatch(receiveHomeBannerSuccess(banner));
      } else {
        dispatch(receiveHomeBannerSuccess(null));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const requestHomeEbooks = () => ({
  type: REQUEST_HOME_EBOOKS,
});

const receiveHomeEbooksSuccess = ebooks => ({
  type: RECEIVE_HOME_EBOOKS_SUCCESS,
  payload: {
    ebooks,
  },
});

const receiveHomeEbooksError = error => ({
  type: RECEIVE_HOME_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeEbooks = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeEbooks());
      const lang = langSelector(getState());
      const ebooks = await API.loadArticles(
        {
          limit: 3,
          sitemapUniqueId: 'EBOOKY',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeEbooksSuccess(ebooks));
    } catch (e) {
      dispatch(receiveHomeEbooksError(e));
    }
  };
};

const requestHomeBenefits = () => ({
  type: REQUEST_HOME_BENEFITS,
});

const receiveHomeBenefitsSuccess = benefits => ({
  type: RECEIVE_HOME_BENEFITS_SUCCESS,
  payload: {
    benefits,
  },
});

const receiveHomeBenefitsError = error => ({
  type: RECEIVE_HOME_BENEFITS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeBenefits = () => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const homeBenefits = firstHomeBenefitsSelector(state);

      if (!homeBenefits) {
        dispatch(requestHomeBenefits());
        const benefitsArray: any[] = [];
        benefitsArray.push({
          ...(await API.loadOtherTexts('HOME_LONG')),
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('HOME_BENEFITS_1')),
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('HOME_BENEFITS_2')),
        });
        dispatch(receiveHomeBenefitsSuccess(benefitsArray));
      }
    } catch (e) {
      dispatch(receiveHomeBenefitsError(e));
    }
  };
};

export const loadProductList = (listId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();

      const currency = currencySelector(state);

      const lang = langSelector(state);

      dispatch(requestHomeNewProducts());
      const products = await API.loadProductList(
        listId,
        {
          limit: 12,
          offset: 0,
          withProductLabels: '1',
          inWishlist: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );

      if (products && products.products && products.products.length > 0) {
        dispatch(receiveHomeNewProductsSuccess(products));
      } else {
        const products = await API.searchProducts(
          {
            limit: 12,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isNew: '1',
            withProductPackages: '1',
            goodsWithStores: '1',
            withProductLabels: '1',
            inWishlist: '1',
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );
        dispatch(receiveHomeNewProductsSuccess(products));
      }
    } catch (e) {
      console.log({ error: e });
      dispatch(receiveHomeNewProductsSuccess(null));
    }
  };
};
