import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import {
  loadHomeNewProducts,
  loadHomeSaleProducts,
  loadHomeBanner,
  loadHomeBenefits,
  loadProductList,
} from './actions';
import { langSelector, titleSelector } from '../Category/selectors';
import {
  saleProductsSelector,
  newProductsSelector,
  adBannerSelector,
  homeCompanyInfoSelector,
  firstHomeBenefitsSelector,
} from './selectors';
import Home from '../../components/Home/Home';
import {
  productCategoriesSelector,
  productCategoriesIsFetchingSelector,
  homeDimmerSelector,
  firstCategoryIdSelector,
} from '../Header/selectors';
import { loadCategoriesTree } from '../Header/actions';
import { cartDataSelector } from '../Cart/cartSlice';
import {
  fetchContactInfo,
  basicContactInfoSelector,
  secondContactInfoSelector,
} from '../Contact/contactSlice';
import { debounce } from 'lodash';
import { __r } from 'react-i18n';
import { addToFavorite, removeFromFavorite } from '../Product/actions';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  defaultTitle: string;
  lang: string;
  modal: any;
  adBanner: any;
  router: any;
  location: any;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  productCategories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching: boolean;
  dimmerVisible: boolean;
  firstCategoryId: number;
  user: any;
  cart: any;
  basicData: ThenArg<typeof API.loadSitemap>;
  secondData: ThenArg<typeof API.loadSitemap>;
  firstBenefits: any[];
}

class HomeContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      const firstCategoryId = await dispatch(loadCategoriesTree());
      await Promise.all[
        (await dispatch(fetchContactInfo()),
        // await dispatch(loadHomeNewProducts(firstCategoryId)),
        // await dispatch(loadHomeSaleProducts(firstCategoryId)),
        await dispatch(loadProductList('LANDING_PAGE')),
        await dispatch(loadHomeBanner()),
        await dispatch(loadHomeBenefits()))
      ];

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      productCategories,
      productCategoriesIsFetching,
      saleProducts,
      newProducts,
      dimmerVisible,
      dispatch,
      firstCategoryId,
      user,
      cart,
      adBanner,
      basicData,
      secondData,
      firstBenefits,
    } = this.props;

    return (
      <Home
        categories={productCategories}
        //addToCart={this.addToCart}
        //addToRequest={this.addToRequest}
        productCategoriesIsFetching={productCategoriesIsFetching}
        newProducts={newProducts}
        saleProducts={saleProducts}
        dimmerVisible={dimmerVisible}
        dispatch={dispatch}
        firstCategoryId={firstCategoryId}
        user={user}
        cart={cart}
        adBanner={adBanner}
        basicData={basicData}
        secondData={secondData}
        homeCompanyInfo={firstBenefits}
        addToWishList={this.addToWishList}
      />
    );
  }

  public addToWishList = debounce(async (productId, goodId, add) => {
    const { dispatch, user } = this.props;
    if (!user) {
      window.location.href = __r('routes:prihlasenie', '/prihlasenie');
    }
    if (add) {
      await Promise.all([dispatch(addToFavorite(productId, goodId))]);
    } else {
      await Promise.all([dispatch(removeFromFavorite(productId, goodId))]);
    }
  }, 300);

  /*private addToCart = (product: any) => {
    this.props.dispatch(setAddToCartModalVisibility(false, product));
  };

  private addToRequest = (product: any) => {
    this.props.dispatch(setAddToCartModalVisibility(true, product));
  };*/
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    newProducts: newProductsSelector(state),
    saleProducts: saleProductsSelector(state),
    productCategories: productCategoriesSelector(state),
    productCategoriesIsFetching: productCategoriesIsFetchingSelector(state),
    dimmerVisible: homeDimmerSelector(state),
    firstCategoryId: firstCategoryIdSelector(state),
    user: state.auth.user,
    cart: cartDataSelector(state),
    adBanner: adBannerSelector(state),
    basicData: basicContactInfoSelector(state),
    secondData: secondContactInfoSelector(state),
    firstBenefits: firstHomeBenefitsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'HomeContainer' })(HomeContainer),
);
