import API from './services/API';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from './containers/App/constants';

export async function loadTranslations(location) {
  const fromCookie = cookies.load(LANG_COOKIE) || 'sk';
  return API.loadLangTranslation(fromCookie);
}

export function resolveLangFromCookies(hostname: string): string {
  return cookies.load(LANG_COOKIE) || 'sk';
}

export function resolveLangByHostname(hostname: string): string {
  switch (hostname) {
    case 'beta.vzdy.sk':
      return 'sk';
    case 'beta.vzdy.cz':
      return 'cz';
    default:
      return 'sk';
  }
}

export function resolveCurrencySignFromCurrency(currency: string): string {
  if (currency === 'CZK') {
    return 'Kč';
  } else {
    return '€';
  }
}
