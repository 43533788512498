import { prop } from 'eshop-defaults';

enum THEMES {
  DEFAULT = '',
  HEAT = 'heatCategory',
  WOOD = 'woodCategory',
  REST = 'restCategory',
}

enum CategoryNames {
  SPOJ_MATERIAL = 'SPOJ_MATERIAL',
  CENTRUM_VYKUROVANIA = 'CENTRUM_VYKUROV',
  PRE_DREVAROV = 'PRE_DREVAROV',
  OSTATNE = 'OSTATNE',
}

export const resolveCurrentThemeFromCategory = (categoryIds: string[]) => {
  if (categoryIds.includes(CategoryNames.CENTRUM_VYKUROVANIA)) {
    return THEMES.HEAT;
  }

  if (categoryIds.includes(CategoryNames.PRE_DREVAROV)) {
    return THEMES.WOOD;
  }

  if (categoryIds.includes(CategoryNames.OSTATNE)) {
    return THEMES.REST;
  }

  return THEMES.DEFAULT;
};

export const getCategoryIdsFromProduct = (product: any): string[] => {
  const pCategories = prop(product, 'parent_categories', []);
  const uniqueCatIds: string[] = [];
  pCategories.map(p => uniqueCatIds.push(p.category_number));
  return uniqueCatIds;
};

export const getCategoryBannerUrl = (data: any): string => {
  const photogallery = prop(data, 'content.0.photogallery', []);
  let url = '';
  if (photogallery.length) {
    const content = prop(photogallery, '0', []);

    url = `${process.env.REACT_APP_IMAGE_BASE_URL}/image?query=media/${prop(
      content,
      'content_id',
    )}/${prop(content, 'document_name')}&width=928&height=219&webp=0`;
  }

  return url;
};
