import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexCol,
  MobileNavigationItem as MobileNavItem,
  TextP,
  SanitasMobileNavSubItem as MobileNavSubItem,
  SvgIcon,
  FlexRow,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { Link } from 'react-router';
import { __ } from 'react-i18n';
import { IconType } from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import { theme } from '../../theme/theme';
import { prop } from '../../utilities';
import BlogMenuItem from './BlogMenuItem';

export interface Props {
  items: ThenArg<typeof API.loadTree>;
  closeMenu: () => void;
  location?: any;
}

function MobileNavigation({ items, closeMenu, location }: Props) {
  const [level, setLevel] = useState<number>(0);
  const [title, setTitle] = useState<string>('Menu');
  const [actualCategory, setActualCategory] = useState<{ url: any; id: any }>();
  const [currentItems, setCurrentItems] = useState<any>(items);
  const [previous, setPrevious] = useState<
    Array<{ items: any; title: string; actualCategory: any }>
  >([]);

  const upLevel = (id: number = -1) => {
    const currentLvl = level;

    if (currentLvl === 1) {
      setPrevious([
        {
          items,
          title: 'Menu',
          actualCategory: null,
        },
      ]);
    }

    if (currentLvl > -1 && id >= 0) {
      const item = currentItems[id];
      if (item) {
        const newItems = currentItems[id].children;
        const newTitle = currentItems[id].category_name;
        const newPrevious = previous;
        const catUrl = prop(item, 'url', '');
        const catId = prop(item, 'category_id', '');
        setActualCategory({ url: catUrl, id: catId });

        newPrevious.push({
          items: currentItems,
          title,
          actualCategory,
        });

        setPrevious(newPrevious);
        setLevel(level + 1);
        setTitle(newTitle);
        setCurrentItems(newItems);
      }
    }
  };

  const downLevel = () => {
    const currentLvl = level;

    if (currentLvl > 0) {
      setLevel(level - 1);
      setTitle(previous[previous.length - 1].title);
      setCurrentItems(previous[previous.length - 1].items);
      setActualCategory(previous[previous.length - 1].actualCategory);

      const newPrevious = previous;
      newPrevious.splice(-1, 1);
      setPrevious(newPrevious);
    }
  };

  return (
    <Wrapper>
      <IconTitleWrapper>
        <LeftWrapper level={level}>
          {level > 0 ? (
            <StyledSvgIcon
              icon={IconType.back}
              alt="Späť"
              viewBox="0 0 18 18"
              width={20}
              height={20}
              cursor={'pointer'}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={downLevel}
              fill={theme.colors.black}
            />
          ) : null}
          <Title>{title}</Title>
        </LeftWrapper>
        <RightWrapper>
          <SvgIcon
            icon={IconType.close}
            alt="Zavrieť menu"
            viewBox={'0 4 22 24'}
            width={18}
            height={18}
            cursor={'pointer'}
            fill={'black'}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={closeMenu}
          />
        </RightWrapper>
      </IconTitleWrapper>
      {level > 0 && actualCategory ? (
        <MobileNavSubItem
          key={100}
          id={actualCategory.id}
          showArrow={false}
          name={__('Všetky')}
          url={actualCategory.url}
          onClickLink={closeMenu}
          onClick={closeMenu}
          // tslint:disable-next-line:jsx-no-lambda
          isCurrent={false}
        />
      ) : null}
      {renderContentItems(level, upLevel, closeMenu, currentItems, location)}
      <StyledBlogMenuItem onClick={closeMenu} />
    </Wrapper>
  );
}

const renderContentItems = (
  level,
  upLevel,
  closeMenu,
  currentItems,
  location,
) => {
  const rightSrc = '/images/arrow_dark.svg';
  const placeholderImgSrc = '/images/zemplin/placeholder.svg';

  return (
    currentItems &&
    currentItems.map((category, i) => {
      let isCurrent = false;
      if (location) {
        isCurrent = location.pathname.includes(category.url);
      }
      return category?.counts > 0 ? (
        <MobileNavSubItem
          key={category.category_id}
          imgSrc={
            category.image
              ? getImagePath(
                  category.image,
                  {
                    width: 24,
                    height: 24,
                  },
                  false,
                  false,
                  true,
                )
              : placeholderImgSrc
          }
          showArrow={category.children.length > 0}
          name={category.category_name}
          url={category.url}
          id={category.category_id}
          onClickLink={closeMenu}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => upLevel(i)}
          isCurrent={isCurrent}
        />
      ) : null;
    })
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const IconTitleWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  justify-content: space-between;
  margin: ${rem(26)} 0;
  padding: 0 ${rem(16)};
`;

const StyledBlogMenuItem = styled(BlogMenuItem)`
  margin-top: ${rem(680)};
`;

const Title = styled(TextP)`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: ${rem(14)};
  letter-spacing: 0;
  text-transform: lowercase !important;
  :first-letter {
    text-transform: uppercase;
  }
  padding-top: ${rem(1)};
`;

const LeftWrapper = styled(FlexRow)<{ level: number }>`
  align-items: center;

  ${Title} {
    transition: 0.15s all ease;
    padding-left: ${({ level }) => (level > 0 ? rem(36) : rem(0))};
  }
`;
const RightWrapper = styled(FlexRow)``;

const StyledSvgIcon = styled(SvgIcon)`
  margin-left: ${rem(8)};
  position: absolute;
  margin-top: ${rem(6)};
`;

export { MobileNavigation };
