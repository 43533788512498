import {
  SanitasWasAddedToCartModal as WasAddedToCartModal,
  FlexCol,
  ZemplinLoaderWrapper,
  FlexRow,
  AddedToCartBoughtTooMobile,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import {
  wasAddedToCartModalSelector,
  wasAddedToCartModalProductSelector,
  setWasAddedToCartModalVisibility,
  wasAddedToCartModalCountSelector,
  loadProductInCartRecommended,
  wasAddedToCartModalIsFetchingSelector,
  wasAddedToCartModalBoughtTooSelector,
  setAddToCartModalVisibility,
  freeDelRemainingSelector,
  loadProductInCartByCategory,
  wasAddedToCartModalGoodIdSelector,
} from './cartSlice';
import SecondSlider from '../../components/_helpers/Slider/SecondSlider';
import { __ } from 'react-i18n';
import { cartIndexRoute } from '../../utilities/cart';
import { WithRouterProps, withRouter } from 'react-router';
import API, { ThenArg } from '../../services/API';
import {
  generalFreeDeliveryInfoSelector,
  isPriceWithVatSelector,
} from '../App/selectors';
import { prop } from '../../utilities';
import SliderComponent from '../../components/_helpers/Slider/Slider';

interface Props {
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalIsFetching: boolean;
  wasAddedToCartModalBoughtToo: ThenArg<typeof API.loadProductConnections>;
  wasAddedToCartModalGoodId: number;
  freeDeliveryRemainingAmount: any;
  freeDeliveryInfo: any;
  dispatch: any;
  user: any;
  isPriceWithVat: boolean;
  data;
}

function AddedToCartModal({
  wasAddedToCartModal,
  wasAddedToCartModalProduct,
  wasAddedToCartModalCount,
  wasAddedToCartModalIsFetching,
  wasAddedToCartModalBoughtToo,
  wasAddedToCartModalGoodId,
  freeDeliveryRemainingAmount,
  freeDeliveryInfo,
  isPriceWithVat,
  dispatch,
  router,
  data,
}: Props & WithRouterProps) {
  React.useEffect(() => {
    if (wasAddedToCartModalProduct) {
      const mainCategoryId = prop(
        wasAddedToCartModalProduct,
        'parent_categories.0.category_id',
        null,
      );
      if (mainCategoryId) {
        dispatch(loadProductInCartByCategory(mainCategoryId));
      }
    }
  }, [wasAddedToCartModalProduct, dispatch]);

  const redirectToUrl = () => {
    dispatch(setWasAddedToCartModalVisibility());
    router.push('/kosik');
  };

  return (
    <>
      {wasAddedToCartModal && wasAddedToCartModalProduct && (
        <WasAddedToCartModal
          wasAddedToCartModalIsFetching={wasAddedToCartModalIsFetching}
          wasAddedToCartModalBoughtToo={wasAddedToCartModalBoughtToo}
          wasAddedToCartModalGoodId={wasAddedToCartModalGoodId}
          handleClose={() => dispatch(setWasAddedToCartModalVisibility())}
          isActive={true}
          redirectToUrl={redirectToUrl}
          count={wasAddedToCartModalCount}
          product={wasAddedToCartModalProduct}
          freeDeliveryRemainingAmount={freeDeliveryRemainingAmount}
          freeDeliveryInfo={freeDeliveryInfo}
          isPriceWithVat={isPriceWithVat}
        >
          {wasAddedToCartModalIsFetching ? (
            <ZemplinLoaderWrapper height={140} />
          ) : (
            wasAddedToCartModalBoughtToo &&
            wasAddedToCartModalBoughtToo.products.length > 0 && (
              <WasAddedToCartModalBoughtTooWrapper>
                <MobileWrapper>
                  <StyledTitleH2>{__('Mohlo by vás zaujímať')} </StyledTitleH2>{' '}
                  <AddedToCartBoughtTooMobile
                    products={wasAddedToCartModalBoughtToo.products}
                    handleClose={() =>
                      dispatch(setWasAddedToCartModalVisibility())
                    }
                    router={router}
                  />
                </MobileWrapper>
                <DesktopWrapper>
                  <StyledTitleH2>{__('Mohlo by vás zaujímať')} </StyledTitleH2>{' '}
                  <SliderComponent
                    type="add-to-cart-popup"
                    slides={wasAddedToCartModalBoughtToo.products}
                    handleClose={() =>
                      dispatch(setWasAddedToCartModalVisibility())
                    }
                    router={router}
                  />
                </DesktopWrapper>
              </WasAddedToCartModalBoughtTooWrapper>
            )
          )}
        </WasAddedToCartModal>
      )}
    </>
  );
}

const Title = styled.h4`
  font-size: ${rem(20)};
  font-weight: 400;
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const WasAddedToCartModalBoughtTooWrapper = styled(FlexRow)`
  padding: 0 ${rem(26)} ${rem(24)} ${rem(24)};
  ${({ theme }) => theme.mediab.m600`
  padding: 0 ${rem(24)} 0 ${rem(24)};
  `}
`;

const MobileWrapper = styled.div`
  display: none;
  width: 100%;
  ${({ theme }) => theme.mediab.m600`
    display: block;
    `}
`;
const DesktopWrapper = styled.div`
  display: block;
  width: 100%;
  ${({ theme }) => theme.mediab.m600`
    display: none;
    `}
`;
const StyledTitleH2 = styled(Title)`
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(28)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin: 0;
  padding-bottom: ${rem(24)};
  ${({ theme }) => theme.mediab.m600`
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    padding-top: ${rem(8)};
  `}
`;
const mapStateToProps = state => {
  return {
    wasAddedToCartModal: wasAddedToCartModalSelector(state),
    wasAddedToCartModalProduct: wasAddedToCartModalProductSelector(state),
    wasAddedToCartModalCount: wasAddedToCartModalCountSelector(state),
    wasAddedToCartModalIsFetching: wasAddedToCartModalIsFetchingSelector(state),
    wasAddedToCartModalBoughtToo: wasAddedToCartModalBoughtTooSelector(state),
    wasAddedToCartModalGoodId: wasAddedToCartModalGoodIdSelector(state),
    freeDeliveryRemainingAmount: freeDelRemainingSelector(state),
    freeDeliveryInfo: generalFreeDeliveryInfoSelector(state),
    user: state.auth.user,
    isPriceWithVat: isPriceWithVatSelector(state),
    data: state.cart,
  };
};

export default connect(mapStateToProps)(withRouter(AddedToCartModal));
