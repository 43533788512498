import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  SanitasFooterLastRow as FooterLastRow,
  SanitasFooterFirstRow as FooterFirstRow,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import NewsletterForm from '../Home/NewsletterForm';

const FooterWrapper = styled.footer`
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;

  @media print {
    display: none;
  }
  ${({ theme }) => theme.mediab.m580`
    margin-top: 0;
  `};
`;

interface Props {
  items: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  public render() {
    const { items }: any = this.props;

    const images = [
      { source: '/images/sanitaske/visa.svg', title: 'visa' },
      { source: '/images/sanitaske/mastercard.svg', title: 'mastercard' },
      { source: '/images/sanitaske/maestro.svg', title: 'maestro' },
      { source: '/images/sanitaske/discover.svg', title: 'discover' },
      { source: '/images/sanitaske/diners_club.svg', title: 'diners_club' },
    ];

    return (
      <FooterWrapper>
        <NewsletterForm />
        <FooterFirstRow images={images} links={items} />
        <FooterLastRow
          leftText={__(
            'Copyright © 2021 SANITAS – zdravotnícke pomôcky, s.r.o.',
          )}
        />
      </FooterWrapper>
    );
  }
}

export default Footer;
