import React from 'react';
import {
  FlexRowCenterVertical,
  FlexCol,
  TextP,
  SanitasSvgIcon as SvgIcon,
  FlexRow,
  SanitasIconType as IconType,
} from 'eshop-defaults';
import styled, { css } from '../../theme/styled-components';
import { connect } from 'react-redux';
import { __, __r } from 'react-i18n';
import { Link } from 'react-router';
import { rem, rgba } from 'polished';
import { fetchCart, cartIdSelector } from '../../containers/Cart/cartSlice';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';

interface Props {
  user: any;
  dispatch: any;
  cartId: string;
}

function HeaderProfile({ user, dispatch, cartId }: Props) {
  const { name, surname, email } = user || {};
  const userName =
    name || surname ? `${name || ''} ${surname || ''}` : `${email}`;

  const handleLogoutUser = async () => {
    await dispatch(
      logoutUser(cartId, () => {
        dispatch(fetchCart(true));
      }),
    );
  };

  return (
    <Wrapper>
      <StyledLink
        to={
          user
            ? __r('routes:moj-ucet', '/moj-ucet')
            : __r('routes:prihlasenie', '/prihlasenie')
        }
      >
        <SvgIcon
          icon={IconType.login}
          alt="user-icon"
          cursor={'pointer'}
          viewBox="0 0 16 16"
          width={16}
          height={16}
        />
      </StyledLink>
      <NameLinkWrapper>
        {user ? (
          <>
            <TextLink to={__r('routes:moj-ucet', '/moj-ucet')}>
              {userName}
            </TextLink>
            {/* <LinkIconWrapper>
              <MyAccountText>{__('Môj účet')}</MyAccountText>
              <IconImg
                width={8}
                height={4}
                src="/images/arrow_triangle_black.svg"
                alt="arrow-down"
              />
            </LinkIconWrapper> */}
            <Separator>|</Separator>
            <TextWrapper onClick={handleLogoutUser}>
              <Text>{__('Odhlásiť sa')}</Text>
            </TextWrapper>
          </>
        ) : (
          <>
            <TextLink to={__r('routes:prihlasenie', '/prihlasenie')}>
              {__('Prihlásenie')}
            </TextLink>
            <Separator>|</Separator>
            <TextLink to={__r('routes:registracia', '/registracia')}>
              {__('Registrácia')}
            </TextLink>
          </>
        )}
      </NameLinkWrapper>
    </Wrapper>
  );
}

const StyledLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(FlexRowCenterVertical)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  transition: color 0.2s ease;
  justify-content: flex-end;
  margin-bottom: ${rem(16)};
  background-color: ${({ theme }) => rgba(theme.colors.tertiary, 0.08)};
  border-radius: 4px;
  padding: ${rem(8)} ${rem(12)};

  svg {
    fill: ${({ theme }) => theme.colors.tertiary};
  }

  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    height: ${rem(40)};
    padding-right:${rem(16)};
    margin-bottom: 0;
    background-color: ${({ theme }) => rgba(theme.colors.tertiary, 0.04)};
  `}
`;

const NameLinkWrapper = styled(FlexRow)`
  margin-left: ${rem(8)};
  align-items: center;
`;

const headerTextLikecss = css`
  display: inline-block;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  line-height: ${rem(17)};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.p`
  ${headerTextLikecss};
`;

const TextLink = styled(Link)`
  ${headerTextLikecss};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    cartId: cartIdSelector(state),
  };
};

const TextWrapper = styled.div`
  display: block;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0 ${rem(8)};
  ${headerTextLikecss};
`;

export default connect(mapStateToProps)(HeaderProfile);
