import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  SanitasCartItemsPart as CartItemsPart,
  SanitasEmptyCart as EmptyCart,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  addItemToCart,
  removeItemFromCart,
  changeItemNote,
  changeCartNote,
  fetchCart,
  addGiftCard,
  removeGiftCard,
  updateCart,
} from './cartSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { customerInfoSelector } from '../MyAccount/myAccountSlice';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  freeDel: number | null;
  isPriceWithVat: boolean;
  productHasPaint: boolean;
  general: any;
}

class CartFirstStep extends React.Component<Props> {
  // public static async getInitialProps(props) {
  //   // const { dispatch, data } = props;
  //   try {
  //     // if (props.user) {
  //     //   dispatch(updateCart({ ...data, step: 1 }));
  //     // }
  //     return;
  //   } catch (exp) {
  //     console.log(exp);
  //     return;
  //   }
  // }

  public render() {
    const {
      data,
      isFetching,
      freeDel,
      isPriceWithVat,
      productHasPaint,
      user,
      general,
    } = this.props;
    if (!data) {
      return null;
    }

    const { items } = data;
    return (
      <>
        <MetaTags tags={{ title: __('Košík - Nákupný košík') }} />
        {items.length === 0 ? (
          <EmptyCart />
        ) : (
          <CartItemsPart
            data={data}
            user={user}
            isFetching={isFetching}
            addToRequest={this.addToRequest}
            addToCart={this.addToCart}
            removeFromCart={this.removeFromCart}
            changeCartItemNote={this.changeCartItemNote}
            changeCartNote={this.changeCartNote}
            refreshCart={this.refreshCart}
            freeDel={freeDel}
            isPriceWithVat={isPriceWithVat}
            handlePaintChange={this.handlePaintChange}
            productHasPaint={productHasPaint}
            addGiftCard={this.handleAddGiftCard}
            removeGiftCard={this.handleRemoveGiftCard}
            handlePointsChange={this.handlePointsChange}
            pointsAmountRatio={prop(general, 'pointsAmountRatio', 0)}
          />
        )}
      </>
    );
  }

  private handleAddGiftCard = (cardNumber: string) => {
    this.props.dispatch(addGiftCard(cardNumber));
  };

  private handleRemoveGiftCard = (cardNumber: string) => {
    this.props.dispatch(removeGiftCard(cardNumber));
  };

  private handlePaintChange = (goodId: number, wantPaint: boolean) => {
    this.props.dispatch(changeItemNote(goodId, wantPaint ? 'S potlačou' : ''));
  };

  private refreshCart = () => {
    this.props.dispatch(fetchCart(true));
  };

  private addToRequest = (product: any, count: number) => {
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      addItemToCart(
        cartProduct,
        prop(product, 'good.good_id'),
        prop(product, 'product.product_id'),
        count,
        true,
        false,
      ),
    );
  };

  private addToCart = (product: any, count: number) => {
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      addItemToCart(
        cartProduct,
        prop(product, 'good.good_id'),
        prop(product, 'product.product_id'),
        count,
        false,
        false,
      ),
    );
  };

  private removeFromCart = (pId: number, gId: number, isRequest?: boolean) => {
    this.props.dispatch(removeItemFromCart(gId, pId, isRequest));
  };

  private changeCartItemNote = (goodId: number, note: string) => {
    this.props.dispatch(changeItemNote(goodId, note));
  };

  private changeCartNote = (note: string) => {
    this.props.dispatch(changeCartNote(note));
  };
  private handlePointsChange = (points: number) => {
    this.props.dispatch(
      updateCart({ ...this.props.data, consumed_points: points }),
    );
  };
}

const mapStateToProps = state => {
  return {
    user: customerInfoSelector(state),
    general: state.general,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartFirstStep' })(CartFirstStep),
);
