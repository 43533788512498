import * as React from 'react';
// import styled from 'styled-components';
// import { rem } from 'polished';
import { connect } from 'react-redux';
// import { FlexCol } from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { connectSsr } from 'ssr-service';
// import { __ } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import LazyLoad from 'react-lazyload';
import CartLogic from './CartLogic';
import {
  cartDataSelector,
  cartIsFetchingSelector,
  cartErrorSelector,
  updateCart,
  fetchCart,
  freeDelRemainingSelector,
  cartShowProblemsSelector,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import {
  customerInfoSelector,
  deliveryAddressesIsFetchingSelector,
  deliveryAddressesSelector,
  fetchCustomerDetails,
  fetchDeliveryAddresses,
} from '../MyAccount/myAccountSlice';
import { userSelector, isPriceWithVatSelector } from '../App/selectors';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { prop } from '../../utilities';

interface Props {
  dispatch: (action: any) => void;
  children: any;
  user: any;
  data: any;
  error: { details: { description: string } };
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  deliveryAddressesIsFetching: boolean;
  freeDel: number | null;
  isPriceWithVat: boolean;
  showProblems: boolean;
  general: any;
  userInfo: any;
}

class Cart extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      if (props.user) {
        await Promise.all[
          (await dispatch(fetchDeliveryAddresses()),
          await dispatch(fetchCart()),
          await dispatch(fetchCustomerDetails(true)))
        ];
      } else {
        await dispatch(fetchCart());
      }
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const {
      children,
      data,
      error,
      deliveryAddresses,
      isFetching,
      deliveryAddressesIsFetching,
      dispatch,
      freeDel,
      isPriceWithVat,
      showProblems,
      userInfo,
      general,
    } = this.props;

    return (
      <>
        <BreadCrumb withWrapper={true} />
        <CartLogic
          key={1}
          handleAcceptTermsChange={this.handleAcceptTermsChange}
          handleAcceptNewsLetterChange={this.handleAcceptNewsLetterChange}
          handleAcceptHeurekaChange={this.handleAcceptHeurekaChange}
          createOrder={this.createOrder}
          dispatch={dispatch}
          data={data}
          isFetching={isFetching}
          error={error}
          deliveryAddresses={deliveryAddresses}
          deliveryAddressesIsFetching={deliveryAddressesIsFetching}
          freeDel={freeDel}
          isPriceWithVat={isPriceWithVat}
          showProblems={showProblems}
          user={userInfo}
          pointsAmountRatio={prop(general, 'pointsAmountRatio', 0)}
        >
          {children}
        </CartLogic>
      </>
    );
  }

  private handleAcceptTermsChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, terms_accept: e.target.value }),
    );
  };

  private handleAcceptNewsLetterChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, newsletter_accept: e.target.value }),
    );
  };

  private handleAcceptHeurekaChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, heureka: e.target.value }),
    );
  };

  private createOrder = async (): Promise<string> => {
    let orderPublicId = '';

    try {
      const res = await API.createOrder(
        this.props.data.id,
        {},
        { ...this.props.data, step: 3 },
      );
      orderPublicId = res.orderPublicId;
    } catch (err) {
      console.log({ err });
    }

    return orderPublicId;
  };
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    userInfo: customerInfoSelector(state),
    data: cartDataSelector(state),
    isFetching: cartIsFetchingSelector(state),
    error: cartErrorSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
    deliveryAddressesIsFetching: deliveryAddressesIsFetchingSelector(state),
    freeDel: freeDelRemainingSelector(state),
    isPriceWithVat: isPriceWithVatSelector(state),
    showProblems: cartShowProblemsSelector(state),
    general: state.general,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart' })(withRouter(Cart)),
);
