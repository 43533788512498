import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FlexRow, SanitasBreadCrumb } from 'eshop-defaults';
//import { BreadCrumbPath } from './breadCrumbSlice';

interface Props {
  breadCrumb: any;
}

class BreadCrumb extends React.Component<Props> {
  public render() {
    const { breadCrumb }: any = this.props;
    const showBreadCrumbs = breadCrumb && breadCrumb.length > 0;
    return (
      <React.Fragment>
        {showBreadCrumbs && (
          <Wrapper>
            <SanitasBreadCrumb breadCrumb={breadCrumb} />
          </Wrapper>
        )}
      </React.Fragment>
    );
  }
}

const Wrapper = styled(FlexRow)`
  margin: 0 auto;
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
  `}
`;

export default BreadCrumb;
