import * as React from 'react';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { prop, formatDate, stripHtmlTags } from '../../utilities';
import styled from 'styled-components';
import { ArticleMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { __ } from 'react-i18n';
import { resolveArticleContentDetails } from '../../utilities/article';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { getCmsMetaTags } from '../../utilities/metatags';
import { getImagePath } from '../../utilities/product';
import { Article, ArticleBlog, ArticleList } from 'eshop-defaults';
import { rem } from 'polished';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { connectSsr } from 'ssr-service';
import { loadCMSArticles, loadCMSCategoriesTree } from './actions';
import { allArticlesSelector, sitemapByIdSelector } from './selectors';
import { push } from 'react-router-redux';

export interface CmsProps {
  sitemap: any;
  sitemapTree: any;
  parentSitemap: any;
  isError: boolean;
  articles: any;
  isArticle: boolean;
  contentData: ThenArg<typeof API.loadArticleDetail>;
  draftContent: boolean;
  lang: string;
  allArticles: ThenArg<typeof API.loadArticles>;
  sitemapsById: object;
  dispatch: (action: any) => void;
}

class Cms extends React.Component<
  CmsProps,
  { photoIndex: number; isOpen: boolean; galleryPhotos: any[] }
> {
  public static async getInitialProps({
    sitemapId,
    contentId,
    lang,
    dispatch,
  }) {
    if (sitemapId || contentId) {
      try {
        const returnProps: any = {};
        returnProps.isArticle = true;
        returnProps.articles = [];
        let articleDetail;
        if (sitemapId) {
          articleDetail = (
            await API.loadSitemap(sitemapId, {}, { xAcceptLanguage: lang })
          ).content;
        } else if (contentId) {
          articleDetail = await API.loadArticleDetail(
            contentId,
            {},
            { xAcceptLanguage: lang },
          );
        }

        if (articleDetail) {
          const redirect = prop(articleDetail, 'json_content.redir', null);
          if (!redirect) {
            await dispatch(loadCMSCategoriesTree());
            await dispatch(
              loadCMSArticles(
                prop(articleDetail, 'sitemap_item_id', null),
                6,
                0,
              ),
            );
          } else {
            dispatch(
              push({
                pathname: `/${redirect}`,
                search: '',
              }),
            );
          }
        }

        dispatch(setBreadCrumbPath(BreadCrumbType.CMS, articleDetail));
        returnProps.contentData = articleDetail;
        return returnProps;
      } catch (exp) {
        console.log(exp);
        return {
          isError: true,
        };
      }
    }
  }

  public constructor(props) {
    super(props);
  }

  public render() {
    const { contentData, isError } = this.props;
    const lastUpdate = prop(contentData, 'last_update');
    const createdAt = prop(contentData, 'created_date');
    const author = prop(contentData, 'author');
    const article = prop(contentData, 'json_content');
    const photogallery = prop(contentData, 'photogallery');
    const sfForm = prop(contentData, 'sf_form', '');

    let titleImage = '';
    if (photogallery) {
      titleImage = prop(photogallery[0], 'document_path');
    }
    const { name, annotation } = resolveArticleContentDetails(contentData);

    return (
      <>
        {isError && !contentData ? (
          <NotFoundArticle>
            {__('Tento článok nebolo možné nájsť')}
          </NotFoundArticle>
        ) : (
          <>
            <MetaTags tags={getCmsMetaTags(article)} />
            <ArticleMicrodata
              headline={name}
              image={
                titleImage
                  ? getImagePath(titleImage, { width: 1920, height: 600 }, true)
                  : ''
              }
              datePublished={
                createdAt ? formatDate(createdAt, 'MM/DD/YYYY') : ''
              }
              description={stripHtmlTags(annotation)}
              dateModified={
                lastUpdate ? formatDate(lastUpdate, 'MM/DD/YYYY') : ''
              }
              author={author}
            />
            <Wrapper className="container container--wide">
              {sfForm && sfForm === 'Article' ? (
                this.renderArticeBlog()
              ) : (
                <>
                  <Article
                    article={contentData}
                    photogallery={photogallery}
                    apiUrl={process.env.REACT_APP_API_BASE_URL!}
                  />
                  <ShareWrapper
                    title={name}
                    desc={stripHtmlTags(annotation)}
                    withTitle={false}
                    image={titleImage}
                  />
                </>
              )}
            </Wrapper>
          </>
        )}
      </>
    );
  }
  public renderArticeBlog = () => {
    const { contentData, sitemapsById, allArticles } = this.props;

    const photogallery = prop(contentData, 'photogallery');
    const articles = prop(allArticles, 'articles');
    let titleImage = '';
    if (photogallery) {
      titleImage = prop(photogallery[0], 'document_path');
    }
    const { name, annotation } = resolveArticleContentDetails(contentData);

    return (
      <>
        <ArticleBlog
          article={contentData}
          photogallery={photogallery}
          apiUrl={process.env.REACT_APP_API_BASE_URL!}
        />
        <ShareWrapper
          title={'Zdieľať:'}
          desc={stripHtmlTags(annotation)}
          withTitle={true}
          image={titleImage}
        />

        <ArticleListWrapper>
          <Text>{__('Podobné príspevky')} </Text>
          <ArticleList
            sitemapsById={sitemapsById}
            articles={articles}
            isFetching={false}
            marginTop={40}
          />
        </ArticleListWrapper>
      </>
    );
  };
}

const Wrapper = styled.div`
  width: 100%;
`;

const NotFoundArticle = styled.p`
  color: indianred;
  margin: 0 auto;
  padding: ${rem(24)};
  font-size: ${rem(24)};
`;

const ArticleListWrapper = styled.div`
  max-width: ${rem(928)};
  margin: 0 auto 0 auto;

  & ${ArticleList} {
    margin-top: 0;
  }
`;
const Text = styled.p`
  font-size: ${rem(22)};
  color: ${({ theme }) => `${theme.colors.black}`};
`;

const mapStateToProps = state => {
  return {
    lang: state.general.lang,
    allArticles: allArticlesSelector(state),
    sitemapsById: sitemapByIdSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CMS' })(Cms),
);
