import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  SanitasTitle as Title,
  SanitasContactPersonBlock as ContactPersonBlock,
  SanitasBlockWithTitle as BlockWithTitle,
  SanitasContactInfoBlock as ContactInfoBlock,
  SanitasInvoiceAddressBlock as InvoiceAddressBlock,
  SanitasCompanyInfo as CompanyInfo,
  SanitasInfoText as InfoText,
  SanitasButtonPrimary as Button,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { fetchCustomerDetails } from '../../containers/MyAccount/myAccountSlice';

interface Props {
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  userId: number;
  dispatchProp?: any;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function MyInfo({ customerInfo, userId, dispatchProp }: Props) {
  const companyRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const {
    meno,
    priezvisko,
    email,
    ico: currentIco,
    dic: currentDic,
    icdph: currentIcDph,
    firma,
    country: currentCountry,
    cislo,
    mesto,
    psc,
    telefon,
    ulica,
  } = customerInfo;
  const isNameSet = meno ? meno !== '' : false;
  const isSurnameSet = priezvisko ? priezvisko !== '' : false;
  const initialState = {
    name: meno || '',
    surname: priezvisko || '',
    ico: currentIco || '',
    dic: currentDic || '',
    icdph: currentIcDph || '',
    company: firma || '',
    country: currentCountry || 'sk',
    streetNumber: cislo || '',
    city: mesto || '',
    zip: psc || '',
    phone: telefon || '',
    street: ulica || '',
    isCompany: firma && currentIco && currentDic && currentIcDph,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onChange = e => {
    const {
      target: { name, value },
    } = e;
    dispatch({ field: name, value: value });
    if (name === 'isCompany' && value) {
      if (companyRef && companyRef.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: companyRef.current.offsetTop,
        });
      }
    }
  };

  const {
    ico,
    dic,
    icdph,
    company,
    country,
    streetNumber,
    street,
    city,
    zip,
    phone,
    isCompany,
    name,
    surname,
  } = state;

  const updateDetails = async () => {
    setIsSaving(true);
    try {
      await API.updateCustomerDetails(
        userId,
        {},
        {
          ...customerInfo,
          meno: name,
          priezvisko: surname,
          firma: company,
          ico,
          dic,
          icdph,
          ulica: street,
          cislo: streetNumber,
          telefon: phone,
          psc: zip,
          mesto: city,
          country,
        },
      );
    } catch (e) {
      setError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setDataUpdated(true);
    await dispatchProp(fetchCustomerDetails());
  };

  React.useEffect(() => {});

  return (
    <Wrapper>
      <InfoTitle marginTop={0}>{__('Moje údaje')}</InfoTitle>
      <DimWrapper dimmed={isSaving}>
        <BlockWithTitle title={'Kontaktná osoba'}>
          <ContactPersonBlock
            onChange={onChange}
            isNameSet={isNameSet}
            isSurnameSet={isSurnameSet}
            name={name}
            surname={surname}
            isCompany={isCompany}
          />
        </BlockWithTitle>
        <BlockWithTitle title={'Kontaktné údaje'}>
          <ContactInfoBlock email={email} phone={phone} onChange={onChange} />
        </BlockWithTitle>
        <BlockWithTitle title={'Fakturačná adresa'}>
          <InvoiceAddressBlock
            onChange={onChange}
            street={street}
            streetNumber={streetNumber}
            city={city}
            zip={zip}
            country={country}
            use_delivery_address={true}
          />
        </BlockWithTitle>
        <CompanyWrapper visible={isCompany} ref={companyRef}>
          <BlockWithTitle title={'Údaje o firme'}>
            <CompanyInfo
              onChange={onChange}
              name={company}
              ico={ico}
              dic={dic}
              icdph={icdph}
            />
          </BlockWithTitle>
        </CompanyWrapper>
      </DimWrapper>
      {!error && dataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Údaje boli úspešne zmenené')}
        />
      )}
      {error && (
        <InfoText
          type={InfoType.ERROR}
          info={__('Pri ukladaní sa vyskytla chyba')}
        />
      )}
      <SaveButton onClick={updateDetails}>
        {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
      </SaveButton>
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  /* max-width: ${rem(1048)}; */
  margin-right: 0;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${rem(1)} solid #C2CED9;
  box-shadow: 0px ${rem(2)} ${rem(8)} #4D617C14;
  border-radius: ${rem(8)};
  padding: ${rem(24)} ${rem(24)} ${rem(10)} ${rem(24)};;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(16)};
  `}
`;

const InfoTitle = styled(Title)`
  ${({ theme }) => theme.mediab.l925`
  margin-bottom: ${rem(48)}`};
`;

const CompanyWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
`;

const DimWrapper = styled(FlexCol)<{ dimmed: boolean }>`
  opacity: ${({ dimmed }) => (dimmed ? 0.4 : 1)};
`;

const SaveButton = styled(Button)`
  max-width: ${rem(111)};
  padding: ${rem(15)} ${rem(32)} ${rem(14)} ${rem(32)};
  width: initial;
  position: relative;
  top: ${rem(-16)};

  &:disabled {
    background-color: #dcd6d6;
  }

  ${({ theme }) => theme.mediab.m600`
    max-width: initial;
    width: 100%;
    top: 0;
  `}
`;

export default MyInfo;
