import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { SanitasCartContactInfoPart as CartContactInfoPart } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { updateCart } from './cartSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  deliveryAddresses: any;
  deliveryAddressesIsFetching: boolean;
  dispatchCartContactInfo: any;
  cartContactInfo: any;
  showProblems: boolean;
}

class CartSecondStepContainer extends React.Component<Props> {
  public render() {
    const {
      data,
      isFetching,
      deliveryAddresses,
      deliveryAddressesIsFetching,
      dispatchCartContactInfo,
      cartContactInfo,
      showProblems,
    } = this.props;

    return (
      <>
        <MetaTags tags={{ title: __('Košík - Osobné údaje') }} />
        <CartContactInfoPart
          data={data}
          updateCart={this.updateCart}
          deliveryAddresses={deliveryAddresses}
          dispatchCartContactInfo={dispatchCartContactInfo}
          cartContactInfo={cartContactInfo}
          showProblems={showProblems}
        />
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, false, false));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartSecondStep' })(CartSecondStepContainer),
);
