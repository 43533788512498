import * as React from 'react';
import { connectSsr } from 'ssr-service';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  langSelector,
  contactInfoSelector,
  partialSearchProductsSelector,
  partialSearchProductsIsFetchingSelector,
  partialSearchCategoriesSelector,
  partialSearchCategoriesIsFetchingSelector,
  searchTermSelector,
  partialSearchCategoryProductsSelector,
} from '../App/selectors';
import Header from '../../components/Header/Header';
import { productCategoriesSelector } from './selectors';
import { cartDataSelector } from '../Cart/cartSlice';

interface Props {
  user: any;
  lang: string;
  productCategories: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  partialSearchCategoryProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
  lastSearchTerm: string;
}

class ContainerHeader extends React.Component<Props> {
  public render() {
    const {
      socialUrls,
      lang,
      contact,
      dispatch,
      productCategories,
      partialSearchProducts,
      partialSearchProductsIsFetching,
      partialSearchCategories,
      partialSearchCategoriesIsFetching,
      user,
      cartData,
      lastSearchTerm,
      partialSearchCategoryProducts,
    } = this.props;

    return (
      <Header
        user={user}
        dispatch={dispatch}
        socialUrls={socialUrls}
        lang={lang}
        contact={contact}
        items={productCategories}
        partialSearchCategoryProducts={partialSearchCategoryProducts}
        partialSearchProducts={partialSearchProducts}
        partialSearchProductsIsFetching={partialSearchProductsIsFetching}
        partialSearchCategories={partialSearchCategories}
        partialSearchCategoriesIsFetching={partialSearchCategoriesIsFetching}
        cartData={cartData}
        lastSearchTerm={lastSearchTerm}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    contact: contactInfoSelector(state),
    socialUrls: state.general.socialUrls,
    productCategories: productCategoriesSelector(state),
    partialSearchCategoryProducts: partialSearchCategoryProductsSelector(state),
    partialSearchProducts: partialSearchProductsSelector(state),
    partialSearchProductsIsFetching: partialSearchProductsIsFetchingSelector(
      state,
    ),
    partialSearchCategories: partialSearchCategoriesSelector(state),
    partialSearchCategoriesIsFetching: partialSearchCategoriesIsFetchingSelector(
      state,
    ),
    user: state.auth.user,
    cartData: cartDataSelector(state),
    lastSearchTerm: searchTermSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContainerHeader' })(ContainerHeader),
);
