/* eslint-disable */
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem, rgba } from 'polished';
import {
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FlexRowCenterVertical,
  SanitasLoaderWrapper as LoaderWrapper,
  SanitasCategoryHeader as CategoryHeader,
  SanitasPagination as Pagination,
  SanitasCategoryFilter as CategoryFilter,
  ZemplinFilterType as FilterType,
  SanitasMobileCategoryFilterButton as MobileCategoryFilterButtons,
  SanitasMobileCategoryFilterMenu as MobileCategoryFilterMenu,
  SanitasLinksFilter as LinksFilter,
  SanitasActiveFilters as ActiveFilters,
  SanitasCategoryTopFilters as CategoryTopFilters,
  SanitasCategoryFilterCheckboxes as CategoryFilterCheckboxes,
  SanitasProductList as CategoryProductList,
  SanitasNoItemsWrapper as NoItemsWrapper,
  SanitasMobileNavMenu as MobileNavMenu,
  SanitasCategoryCard as CategoryCard,
} from 'eshop-defaults';
import { prop, arrayToObject } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { getCategoryMetaTags } from '../../utilities/metatags';
import { setAddToCartModalVisibility } from '../../containers/Cart/cartSlice';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import {
  urlAttribsToObject,
  attribsObjectToUrl,
  hiddenAttribsByCategory,
} from '../../containers/Category/categorySlice';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import { setCustomerGoodOrderNr } from '../../containers/Product/actions';
import {
  isCategory,
  findCategoryIdFromArray,
} from 'eshop-defaults/lib/components/Zemplin/selectors';
import {
  getCategoryIdsFromProduct,
  getCategoryBannerUrl,
} from '../../utilities/category';
import { getImagePath } from '../../utilities/product';
import SideMenu from '../Home/SideMenu';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { SanitasNavigation } from '../Home/Navigation';
export interface Props {
  dispatch: any;
  isFetching: boolean;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  catName: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  addToWishList: (product_id: number, good_id: number, add: boolean) => void;
}

function Category({
  isFetching,
  products,
  data,
  dispatch,
  user,
  catName,
  filterData,
  location,
  router,
  childrenCategories,
  cart,
  categories,
  addToWishList,
}: Props & WithRouterProps) {
  const catUrl = prop(location, 'pathname');
  const query = prop(location, 'search');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);

  const [yPosition, setYPosition] = React.useState(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (window && yPosition > 0 && products && !isFetching) {
        window.scrollTo({ behavior: 'smooth', top: yPosition });
        setYPosition(0);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, 400);
  }, [window, yPosition, products, isFetching]);

  const [isCategoryFilterVisible, setIsCategoryFilterVisible] = React.useState(
    false,
  );
  const [isFilterMenuVisible, setIsFilterMenuVisible] = React.useState(false);
  const categoryName = catName ? catName : prop(data, 'category_name');
  const categoryBannerUrl = getCategoryBannerUrl(data);
  const categoryDesc = prop(data, 'category_descr', '') || '';
  const parentCategories = prop(data, 'parent_categories', null);
  const previousCategory = parentCategories
    ? parentCategories[parentCategories.length - 1]
    : null;

  const productCategoryIds = getCategoryIdsFromProduct(data);
  productCategoryIds.push(prop(data, 'category_number', ''));
  const categoryNumber = findCategoryIdFromArray(productCategoryIds);
  const hiddenAttribs = hiddenAttribsByCategory[categoryNumber]
    ? hiddenAttribsByCategory[categoryNumber]
    : [];

  const categoryLinks = childrenCategories
    .filter(c => c.counts && c.counts > 0)
    .map(c => ({
      name: c.category_name,
      url: resolveCategoryUrl(c.category_id, c.url),
    }));

  const cartItems = prop(cart, 'items', []);
  const cartItemsIds: any = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, data));
  }, [data, dispatch]);

  const toggleCategoryFilterMenu = () => {
    document.body.style.overflow = isCategoryFilterVisible
      ? 'visible'
      : 'hidden';
    setIsCategoryFilterVisible(!isCategoryFilterVisible);
  };

  const toggleFilterMenu = () => {
    document.body.style.overflow = isFilterMenuVisible ? 'visible' : 'hidden';
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };
  const handleAddToWishList = (product_id, good_id, add) => {
    addToWishList(product_id, good_id, add);
  };

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        },
  ) => {
    setYPosition(window.pageYOffset);
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(attribs[attribId], 'attrib_data.min');
          const attribDataMax = prop(attribs[attribId], 'attrib_data.max');

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = {
              min: parseInt(prop(newData, 'min').toFixed(0)),
              max: parseInt(prop(newData, 'max').toFixed(0)),
            };
          } else {
            delete rangeAttribsObj[attribId];
          }
          router.push(
            attribsObjectToUrl(catUrl, {
              ...urlAttribsObj,
              rangeAttribs: rangeAttribsObj,
            }),
          );
        } else {
          urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
          urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
          router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
        }

        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const currentAttribFilters = prop(urlAttribsObj, 'attribs');

        let updatedAttribsObj = currentAttribFilters;
        let values = updatedAttribsObj
          ? prop(currentAttribFilters[attribId], 'values')
          : null;
        if (values) {
          if (newValue) {
            values.push(valueId);
          } else {
            values = values.filter(v => valueId.toString() !== v);
          }
          if (values.length === 0) {
            updatedAttribsObj[attribId].values = null;
            delete updatedAttribsObj[attribId];
          } else {
            updatedAttribsObj[attribId].values = values;
          }
        } else {
          updatedAttribsObj = {
            ...updatedAttribsObj,
            [attribId]: { values: [valueId] },
          };
        }

        router.push(
          attribsObjectToUrl(catUrl, {
            ...urlAttribsObj,
            attribs: updatedAttribsObj,
          }),
        );

        break;
      }

      default:
        break;
    }
  };

  const handleSortChange = e => {
    const value = e.target.value;
    const [sort, sortDir] = value.split('_');
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        sort,
        sortDir: sortDir ? sortDir : null,
      }),
    );
  };

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  // if (isFetching || !products) {
  //   return <ZemplinLoaderWrapper height={200} />;
  // }

  const { limit, offset, total, products: productsArray } = products || {};
  const { minPrice, maxPrice, attribs } = filterData;

  const renderCategoriesFilter = () => {
    return (
      <MobileCategoryFilter>
        {categoryLinks && categoryLinks.length > 0 ? (
          <LinksFilter
            isOpen={true}
            isMobile={true}
            filterData={{ links: categoryLinks }}
          />
        ) : (
          <NoItemsWrapper
            style={{ marginLeft: '0' }}
            height={100}
            text={__('Nenašli sa žiadne ďalšie podkategórie')}
          />
        )}
      </MobileCategoryFilter>
    );
  };

  const renderFilterCheckboxes = () => {
    return (
      <CategoryFilterCheckboxes
        filters={prop(urlAttribsObj, 'otherAttribs')}
        handleCheckboxChange={handleCheckboxChange}
        isB2B={user && user.b2b}
      />
    );
  };

  const renderOtherFilters = () => {
    return (
      <MobileCategoryFilter>
        <CategoryFilter
          name={''}
          type={FilterType.RANGE}
          initialIsOpen={true}
          isMobile={true}
          filterData={{
            min: minPrice,
            max: maxPrice,
            activeMin: prop(urlAttribs, 'min'),
            activeMax: prop(urlAttribs, 'max'),
          }}
          handleFilterChange={handleFilterChange}
          unit={'€'}
        />
        {/* <MobileCheckboxesWrapper> */}
        {renderFilterCheckboxes()}
        {/* </MobileCheckboxesWrapper> */}
      </MobileCategoryFilter>
    );
  };

  const renderRightSide = () => {
    if (isFetching || !attribs) {
      return <LoaderWrapper height={200} />;
    }
    const sort = prop(urlAttribs, 'sort');
    const sortDir = prop(urlAttribs, 'sortDir');

    const sortValue = sort && sortDir ? `${sort}_${sortDir}` : 'none';
    const activeAttribs = JSON.parse(
      JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
    );
    Object.keys(activeAttribs).map(key => {
      const activeValues = activeAttribs[key].values;
      const valuesObj = arrayToObject(attribs[key].values, 'attrib_value');
      const newValues: any[] = [];
      activeValues.map(value => {
        newValues.push({
          valueId: value,
          name: prop(valuesObj, `${value}.info.attrib_value`, value),
          attribName: prop(attribs, `${key}.attrib_data.attrib_name`),
        });
      });
      activeAttribs[key].values = newValues;
    });

    const placeholderImgSrc = '/images/sanitaske/placeholder.svg';
    return (
      <RightWrapper>
        <BreadCrumb />
        <CategoryHeader
          title={categoryName}
          desc={categoryDesc}
          categoryBannerUrl={categoryBannerUrl}
        />
        {isFetching ? (
          <LoaderWrapper height={500} />
        ) : !productsArray ? null : (
          <>
            {childrenCategories && childrenCategories.length > 0 && (
              <ChildrenCategoryCards>
                {childrenCategories.map(c => {
                  return (
                    <>
                      <CategoryCard
                        name={c.category_name}
                        url={resolveCategoryUrl(c.category_id, c.url)}
                        img={
                          c.image
                            ? getImagePath(
                                c.image,
                                {
                                  width: 56,
                                  height: 56,
                                },
                                false,
                                false,
                                false,
                              )
                            : placeholderImgSrc
                        }
                      />
                    </>
                  );
                })}
              </ChildrenCategoryCards>
            )}
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <ActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={catUrl}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </ActiveFiltersWrapper>
            )}
            <SortWrapper>
              <CategoryTopFilters
                handleChange={handleSortChange}
                sortValue={sortValue}
              >
                <CategoryFilter
                  name={''}
                  type={FilterType.RANGE}
                  initialIsOpen={true}
                  isMobile={false}
                  filterData={{
                    min: minPrice,
                    max: maxPrice,
                    activeMin: prop(urlAttribs, 'min'),
                    activeMax: prop(urlAttribs, 'max'),
                  }}
                  handleFilterChange={handleFilterChange}
                  unit={'€'}
                />
                <DesktopCheckboxesWrapper>
                  {renderFilterCheckboxes()}
                </DesktopCheckboxesWrapper>
              </CategoryTopFilters>
            </SortWrapper>
            <MobileFiltersWrapper>
              <MobileCategoryFilterButtons
                onClick={toggleCategoryFilterMenu}
                text={__('Kategórie')}
                iconType={'CATEGORY'}
              />
              <MobileCategoryFilterButtons
                onClick={toggleFilterMenu}
                text={__('Filter')}
                iconType={'FILTER'}
              />
            </MobileFiltersWrapper>
            <CategoryProductList
              isFetching={isFetching}
              products={productsArray}
              //addToCart={addToCart}
              //addToRequest={addToRequest}
              visible={true}
              //user={user}
              //handleOrderNrChange={handleOrderNrChange}
              //cartItemsIds={cartItemsIds}
              isElasticCategory={true}
              addToWishList={handleAddToWishList}
            />
            <Pagination
              query={`${catUrl}${`${query}` ? query : '?'}`}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightWrapper>
    );
  };

  return (
    <>
      {/*<Shadow />*/}
      <Wrapper className="container container--wide">
        <MetaTags tags={getCategoryMetaTags(data)} />
        <LeftWrapper>
          <SanitasNavigation items={categories} location={location} />
        </LeftWrapper>
        {renderRightSide()}
      </Wrapper>
      {isCategoryFilterVisible && (
        <>
          <MobileNavMenu closeMenu={toggleCategoryFilterMenu}>
            <MobileCategoryFilterMenu
              title={__('Kategórie')}
              handleClose={toggleCategoryFilterMenu}
            >
              {renderCategoriesFilter()}
            </MobileCategoryFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
      {isFilterMenuVisible && (
        <>
          <MobileNavMenu closeMenu={toggleFilterMenu}>
            <MobileCategoryFilterMenu
              title={__('Filter')}
              handleClose={toggleFilterMenu}
            >
              {renderOtherFilters()}
            </MobileCategoryFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(56)} !important;
  /* TODO change */
  margin-top: ${rem(32)} !important;
  padding-left: ${rem(16)} !important;
  padding-right: ${rem(16)} !important;

  ${({ theme }) => theme.mediab.l1050`
      margin-bottom: ${rem(48)} !important;
      padding-bottom: 0 !important;
  `}

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
      margin-top: 0 !important;
  `}
`;

const MobileCategoryFilter = styled(FlexCol)`
  width: 100%;
`;

const RightWrapper = styled(FlexCol)`
  /* overflow: hidden; */
  /* width: 76%; */
  /* max-width: ${({ theme }) => `${theme.grid.container.huge}px`}; */
  max-width: calc(100% - 376px);
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    max-width: 100%;
    width: 100%;
  `}
`;

const LeftWrapper = styled.div`
  /* width: 24%; */
  padding-bottom: ${rem(56)};
  min-width: ${rem(328)};
  margin-right: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

const SortWrapper = styled(FlexRowCenter)`
  padding: ${rem(24)};
  border: 1px solid ${rgba('#003463', 0.32)};
  justify-content: space-between;
  background-color: #fff;
  border-radius: ${rem(8)};
  margin-bottom: ${rem(24)};
  box-shadow: 0px 2px 8px #4d617c14;
  ${({ theme }) => theme.mediab.s450`
    margin-top: ${rem(0)};
    margin-bottom: ${rem(24)} !important;
  `}

  ${({ theme }) => theme.mediab.m580`
      border: 0;
      padding: 0;
  `}
`;

const MobileFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  justify-content: space-between;
  margin: 0 -${rem(16)};
  position: relative;
  z-index: 1;
  background-color: #fff;

  ${({ theme }) => theme.mediab.m580`
      display: flex;
      box-shadow: 0 ${rem(1)} ${rem(4)} #00000029;;
      padding: 0 ${rem(8)};
      border-top: ${rem(1)} solid #e8e8e8;
  `};
`;

const ActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l1050`
      margin-top: ${rem(32)};
      margin-bottom: ${rem(32)};
  `}
`;

const DesktopCheckboxesWrapper = styled.div`
  margin-left: auto;
  margin-right: ${rem(2)};

  ${({ theme }) => theme.mediab.m760`
   display: none;
  `};
`;

export const ChildrenCategoryCards = styled.div<{ offset: number }>`
  padding-bottom: ${props => props.offset};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  border-radius: ${rem(8)};
  background-color: #fff;
  border: 1px solid #0034633D;
  margin-bottom: ${rem(24)};
  box-shadow: 0px 2px 8px #4D617C14;
  ${({ theme }) => theme.mediab.l1150`

  `}

  ${({ theme }) => theme.mediab.s450`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: none;
  `}

  ${({ theme }) => theme.mediab.s450`
   display: none;
  `}
`;

const Shadow = styled.div`
  width: 100%;
  height: ${rem(80)};
  position: absolute;
  background: transparent linear-gradient(180deg, #3962a814 0%, #ffffff00 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

export default withRouter(Category);
