import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';

const SanitasNavigationItemMagazin = styled(Link)`
  border-radius: ${rem(4)};
  width: 100%;
  display: flex;
  position: relative;
  margin-top: ${rem(20)};
  text-decoration: none;
  max-width: ${rem(328)};
  background: ${({ theme }) => theme.colors.white};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.tertiary};
    opacity: 0.04;
    z-index: -1;
    transition: all 0.15s ease-in;
  }
`;
const MagazinImageWrapper = styled.div`
  max-width: 50%;
`;
const StyledImg = styled.img``;
const MagazinText = styled.div`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: 500;
  span {
    color: ${({ theme }) => theme.colors.tertiary};
    text-decoration: underline;
  }
`;
interface Props {
  onClick?: () => void;
}
function BlogMenuItem({ onClick }: Props) {
  return (
    <SanitasNavigationItemMagazin
      id={'magazin'}
      to={'/magazin'}
      onClick={onClick}
    >
      <MagazinImageWrapper>
        <StyledImg src="/images/sanitaske/magazine.png" />
      </MagazinImageWrapper>
      <MagazinText>
        {' '}
        Prečítajte si náš <span> Magazín</span>
      </MagazinText>
    </SanitasNavigationItemMagazin>
  );
}

export default BlogMenuItem;
