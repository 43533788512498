import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  prop,
  FlexCol,
  FlexRow,
  InfoText,
  SanitasLeftUpper as LeftUpper,
  SanitasRightUpper as RightUpper,
  SanitasLoaderWrapper as LoaderWrapper,
  SanitasBottomInfo as BottomInfo,
  SanitasImageGallery as ImageGallery,
  SanitasProductList as ProductList,
  getSanitasProductDefaultInfo,
  getProductDetailInfo,
  getDefaultPackageSize,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getProductMetaTags } from '../../utilities/metatags';
import ShareWrapper from '../_helpers/Default/ShareWrapper';
import { round, stripHtmlTags } from '../../utilities';
import {
  addItemToCart,
  setAddToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import {
  getZemplinTrueCount,
  findCategoryIdFromArray,
} from 'eshop-defaults/lib/components/Sanitaske/selectors';
import { setCustomerGoodOrderNr } from '../../containers/Product/actions';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { getCategoryIdsFromProduct } from '../../utilities/category';
import { getImagePath } from '../../utilities/product';
import Modal from '../_helpers/Default/Modal';
import { WithRouterProps, withRouter } from 'react-router';
import { hiddenAttribsInSpecsByCategory } from '../../containers/Category/categorySlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import SideMenu from '../Home/SideMenu';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { go } from 'react-router-redux';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { SanitasNavigation } from '../Home/Navigation';
import SliderComponent from '../_helpers/Slider/Slider';

interface Props {
  user: ThenArg<typeof API.tokeninfo>;
  lang?: string;
  product: ThenArg<typeof API.loadProduct>;
  isFetching: boolean;
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  dispatch: any;
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  categoriesIsFetching;
  addToWishList: (product_id: number, good_id: number, add) => void;
}

function Product({
  product,
  isFetching,
  alternativeProducts,
  isFetchingAlternatives,
  dispatch,
  user,
  location,
  router,
  cart,
  categories,
  categoriesIsFetching,
  addToWishList,
}: Props & WithRouterProps) {
  const bottomRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.PRODUCT, product));
  }, [product, dispatch]);

  React.useEffect(() => {
    const { pathname } = location;
    const url = prop(product, 'url');
    const id = prop(product, 'product_id');
    const queryProductId = prop(router, 'params.product_id');
    if (
      id &&
      queryProductId === id.toString() &&
      url &&
      pathname !== `/produkt/${id}/${url}`
    ) {
      router.replace(`/produkt/${id}/${url}`);
    }
  }, [location, product, router]);

  const queryVariantId = prop(router, 'location.query.variant');
  const cartItems = prop(cart, 'items', []);
  const cartItemsIds = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  const [imageModalVisible, setImageModalVisible] = React.useState(false);
  const [modalCurrentImage, setModalCurrentImage] = React.useState(0);
  const [updatedGoodByQuery, setUpdatedGoodByQuery] = React.useState(false);

  const { photogallery } = getSanitasProductDefaultInfo(product);

  const [currentGood, setCurrentGood] = React.useState({});
  React.useEffect(() => {
    if (product.goods && product.goods.length > 0) {
      const goodIds = product.goods.map(g => g.good_id);
      const currentGood =
        queryVariantId && goodIds.includes(Number(queryVariantId))
          ? product.goods.filter(g => g.good_id === Number(queryVariantId))[0]
          : product.goods.filter(g => g.on_stock_count > 0)[0] ||
            product.goods[0];
      setCurrentGood(currentGood);
    }
  }, [product, queryVariantId]);
  const info = getProductDetailInfo(product);

  const {
    ean,
    attribs,
    name,
    ordernr,
    annotation,
    currency,
    isnew,
    issale,
    picture,
    isSaleB2b,
    unit,
    brand,
    variants,
    goods,
    plu,
    oldPrice,
    saleByPackage,
    internalCode,
  } = info;

  const price = round(currentGood['final_price'], 2).toString();
  const priceWithoutVat = round(
    currentGood['final_price_without_vat'],
    2,
  ).toString();
  const onStock = currentGood['on_stock_count'];
  const pckg = currentGood['package'];
  const warrantyAttrib = attribs.find(a => a.attrib_name === 'Záruka');
  const warranty = prop(warrantyAttrib, 'values.0.attrib_value');

  const [mainImage, setMainImage] = React.useState('');
  React.useEffect(() => {
    let imagePath = '';
    if (product.goods && product.goods.length > 1) {
      imagePath = picture
        ? getImagePath(picture, { width: 320, height: 320 })
        : product.picture
        ? getImagePath(product.picture, {
            width: 320,
            height: 320,
          })
        : '/images/sanitaske/placeholder.svg';
    } else {
      imagePath =
        product.goods && product.goods[0] && product.goods[0].imagePath
          ? getImagePath(product.goods[0].imagePath, {
              width: 320,
              height: 320,
            })
          : product.picture
          ? getImagePath(product.picture, {
              width: 320,
              height: 320,
            })
          : '/images/sanitaske/placeholder.svg';
    }
    setMainImage(imagePath);
  }, [picture, product]);

  const fullscreenImages =
    photogallery || picture
      ? [picture, ...photogallery]
          .filter(a => a)
          .map(doc => {
            const img = getImagePath(doc, {
              width: 800,
              height: 600,
            });
            const thumbImg = getImagePath(doc, {
              width: 94,
              height: 94,
            });
            return {
              original: img,
              thumbnail: thumbImg,
              name: '',
            };
          })
      : [];

  const productCategoryIds = getCategoryIdsFromProduct(product);
  const categoryNumber = findCategoryIdFromArray(productCategoryIds);

  const initialCount = getDefaultPackageSize(pckg);
  const correctinitialCount = saleByPackage ? initialCount : 1;

  const [count, setCount] = React.useState(correctinitialCount);

  const isInWishlist = prop(currentGood, 'is_favorite', false);

  React.useEffect(() => {
    setCount(correctinitialCount);
  }, [correctinitialCount]);

  const isB2b = user && user.b2b;

  const productLabels = prop(product, 'product_label', []);

  const handleAddToWishList = () => {
    const good_id = prop(currentGood, 'good_id');
    const add = isInWishlist ? 0 : 1;
    addToWishList(product.product_id, good_id, add);
  };

  const renderUpperPart = () => {
    return (
      <>
        <StyledWrapper>
          {' '}
          <LeftUpper
            title={name}
            images={fullscreenImages}
            isnew={isnew}
            issale={issale}
            topImage={mainImage}
            openModal={openModal}
            productLabels={productLabels}
            onStock={onStock}
          ></LeftUpper>
          <RightUpper
            handleGoodChange={changeGood}
            title={name}
            plu={plu}
            oldPrice={oldPrice}
            ean={(currentGood && currentGood['ean']) || ean}
            shortDescription={annotation}
            warranty={warranty}
            manufacturer={brand}
            pckg={pckg}
            onStock={onStock}
            price={price}
            priceWithoutVat={priceWithoutVat}
            currency={currency}
            unit={unit}
            count={count}
            handleCountChange={count => setCount(count)}
            addToCart={addToCartWithoutModal}
            variants={variants}
            goods={goods}
            queryVariantId={+queryVariantId}
            updatedGoodByQuery={updatedGoodByQuery}
            setUpdatedGoodByQuery={setUpdatedGoodByQuery}
            internalCode={internalCode}
            shareButton={
              <ShareWrapper
                title={name}
                desc={stripHtmlTags(annotation)}
                withTitle={false}
                image={mainImage}
              />
            }
            saleByPackage={saleByPackage}
            addToWishList={handleAddToWishList}
          />
        </StyledWrapper>
      </>
    );
  };

  const renderBottomPart = () => {
    const { description, downloads } = info;

    return (
      <div ref={bottomRef}>
        <BottomInfo description={description} instructions={downloads} />
      </div>
    );
  };

  const renderRelated = () => {
    return (
      <>
        {!isFetchingAlternatives ? (
          alternativeProducts &&
          alternativeProducts.products &&
          alternativeProducts.products.length > 0 && (
            <React.Fragment>
              <Title>{__('Mohlo by vás zaujímať')}</Title>
              <ListWrapper>
                {/* <ProductList
                  isFetching={isFetching}
                  products={alternativeProducts.products}
                  visible={true}
                  isElasticCategory={false}
                /> */}
                <SliderComponent
                  type="product"
                  slides={alternativeProducts.products}
                  router={router}
                />
              </ListWrapper>
            </React.Fragment>
          )
        ) : (
          <LoaderWrapper height={250} />
        )}
      </>
    );
  };

  const changeGood = (selectedGoodId: number) => {
    const selectedGood = product.goods.find(
      good => good.good_id === selectedGoodId,
    );
    if (selectedGood) {
      const { picture } = product;
      setCurrentGood(selectedGood);
      const imagePath = selectedGood.imagePath
        ? getImagePath(selectedGood.imagePath, { width: 320, height: 320 })
        : picture
        ? getImagePath(picture, { width: 320, height: 320 })
        : '/images/sanitaske/placeholder.svg';
      setMainImage(imagePath);

      const params = new URLSearchParams(window.location.search);
      if (selectedGoodId) {
        params.set('variant', selectedGoodId.toString());
      }
      const newurl = `${window.location.origin}${
        window.location.pathname
      }?${params.toString()}`;
      window.history.replaceState({ path: newurl }, '', newurl);
    } else {
      setCurrentGood({});
    }
  };

  const addToCartWithoutModal = (selectedGoodId: number) => {
    dispatch(addItemToCart(product, selectedGoodId, product.product_id, count));
  };

  const closeImageModal = () => {
    setImageModalVisible(false);
  };

  const setCurrentImage = c => {
    setModalCurrentImage(c);
  };

  const openModal = (index: number = 0) => {
    setImageModalVisible(true);
    setModalCurrentImage(index);
  };

  return (
    <React.Fragment>
      {/*<Shadow />*/}
      <Wrapper className="container container--wide">
        <MetaTags tags={getProductMetaTags(product)} />
        <LeftWrapper>
          <SanitasNavigation items={categories} location={location} />
        </LeftWrapper>
        <RightWrapper>
          {isFetching ? (
            <LoaderWrapper height={300} />
          ) : product ? (
            <React.Fragment>
              <BreadCrumb />
              {renderUpperPart()}
              {renderBottomPart()}
              {renderRelated()}
              {imageModalVisible &&
                fullscreenImages &&
                fullscreenImages.length > 0 && (
                  <Modal size="big" showX={false} closeModal={closeImageModal}>
                    <ImageGallery
                      closeModal={closeImageModal}
                      setCurrentImage={setCurrentImage}
                      items={fullscreenImages}
                      currentIndex={modalCurrentImage}
                      isVertical={false}
                    />
                  </Modal>
                )}
            </React.Fragment>
          ) : (
            <InfoText
              info={__('Nenašiel sa žiadny produkt')}
              type={InfoType.ERROR}
            />
          )}
        </RightWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

const Wrapper = styled(FlexRow)`
  color: #444444;
  width: 100%;
  /* TODO change */
  margin-top: ${rem(32)} !important;
  padding-left: ${rem(16)} !important;
  padding-right: 0 !important;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
      margin-top: 0 !important;
      padding-right: ${rem(16)} !important;
  `}
`;

const LeftWrapper = styled.div`
  /* width: 24%; */
  padding-bottom: ${rem(56)};
  min-width: ${rem(328)};
  margin-right: ${rem(32)};

  ${({ theme }) => theme.mediab.l1400`
    margin-right: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.l1050`
     display: none;
  `}
`;

const RightWrapper = styled.div`
  /* width: 76%; */
  /* max-width: ${({ theme }) => `${theme.grid.container.huge}px`}; */
  max-width: calc(100% - 376px);
  width: 100%;

  ${({ theme }) => theme.mediab.l1050`
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
  `}
`;

const ListWrapper = styled.div`
  height: ${rem(430)};
  margin-bottom: ${rem(56)};
  background-color: #fff;
  position: relative;
  border-radius: ${rem(8)};
  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    margin: 0 auto;
  `}
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 1px;
    background-color: white;
  }
  ${({ theme }) => theme.mediab.s450`
    height: ${rem(364)};
    margin-bottom: ${rem(32)};
    &:after {
      width: 0;
      right: 0;
    }
  `}
`;

const SliderWrapper = styled(FlexCol)``;

const AccessorySliderWrapper = styled(SliderWrapper)`
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
     margin-bottom: ${rem(32)};
  `}
`;

const Title = styled.h4`
  font-weight: 600;
  line-height: ${rem(28)};
  font-family: 'Roboto', sans-serif;
  margin: 0;
  margin-bottom: ${rem(32)};
  font-size: ${rem(24)};
  line-height: 1.2
    ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `};
`;

const UpperWrapper = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
  `}
`;

const StyledWrapper = styled(UpperWrapper)`
  ${({ theme }) => theme.mediab.l925`
    display: flex;
    `}

  ${({ theme }) => theme.mediab.s450`
    margin: initial;
    `}
`;

const Shadow = styled.div`
  width: 100%;
  height: ${rem(80)};
  position: absolute;
  background: transparent linear-gradient(180deg, #3962a814 0%, #ffffff00 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: -1;

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

export default withRouter(Product);
