import {
  TopInfoBanner,
  SanitasLoaderWrapper as LoaderWrapper,
  AddToCartModal,
  prop,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  resetToken,
  setTopText,
} from './actions';
import { CURRENCY_COOKIE } from './constants';
import {
  dimmerVisibilitySelector,
  currentThemeSelector,
  topTextSelector,
} from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
// import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem, rgba } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from '../../theme/styled-components';
import {
  fetchCart,
  addToCartModalVisibleSelector,
  setAddToCartModalVisibility,
  addToCartModalProductSelector,
  addItemToCartFromModal,
  addToCartModalIsRequestSelector,
} from '../Cart/cartSlice';
import AddedToCartModal from '../Cart/AddedToCartModal';
import API from '../../services/API';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { CART_COOKIE_ID } from '../Cart/helpers';

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
interface Props {
  user: any;
  lang: string;
  props: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  menuItems: Array<{
    active: boolean;
    name: string;
    url: string;
    attribs: any;
  }>;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  topText: string;
  cookies: any;
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};
  position: relative;
  border-top: 1px solid ${rgba('#003463', 0.24)};
  &::before {
    content: '';
    background: #f0f3f6 0% 0% no-repeat padding-box;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

const Shadow = styled.div`
  width: 100%;
  height: ${rem(80)};
  top: 130px;
  position: absolute;
  background: transparent linear-gradient(180deg, #3962a814 0%, #ffffff00 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: -1;

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

class App extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, token, user, topText, cookies } = props;

    try {
      await dispatch(checksRedirect(props.location));

      dispatch(setLanguage('sk'));
      dispatch(setCurrency('EUR' || cookie.load(CURRENCY_COOKIE)));

      const cookiesAvailable = cookie.loadAll();
      const userCookie =
        prop(cookies, `${USER_COOKIE}`) || cookie.load(USER_COOKIE);
      if (userCookie) {
        API.setToken(userCookie);
      }

      if (!token || !user) {
        if (userCookie) {
          await dispatch(loginUser(userCookie));
        } else if (
          prop(cookiesAvailable, 'cart_id') ||
          prop(cookiesAvailable, 'cc_cookie')
        ) {
          await dispatch(resetToken());
        }
        // if (process.env.NODE_ENV === 'development') {
        //   await dispatch(
        //     loginUser(
        //       '18fb7908f316ab95cc89d0b99b2a5c37e2c404c38940d2a43a1b3e76d6071bf5b4ebab9f71b7dcc6d521989a1b56e7e258e931319117cc1b8787725bf1bbc900da63b4ba72cd0e3cf6974ae0e929c7164e598480e329ef96eee0a474d25e3e98b626c4eb21a094823e2a9d907eb0881f56f53c19bc0794d97d2f216f3b749810',
        //     ),
        //   );
        // }
      }

      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      await Promise.all[
        ((await dispatch(loadDefaultSettings()),
        await dispatch(loadCategoriesTree()),
        await dispatch(loadFooterData())),
        await dispatch(fetchCart(true, cartCookie)))
      ];

      /*if (!topText) {
        const text = await API.loadOtherTexts('HEADER');
        await dispatch(setTopText(prop(text, 'content.json_content.body')));
      }*/

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      user,
      currentTheme,
      isLoaded,
      token,
      dispatch,
      addToCartModal,
      addToCartModalProduct,
      addToCartModalIsRequest,
      topText,
      lang,
    } = this.props;

    const currentThemeColors =
      !currentTheme || !theme[currentTheme]
        ? {
            primary: theme.colors.primary,
            navSecondary: theme.colors.navSecondary,
            paginationSecondary: theme.colors.paginationSecondary,
          }
        : {
            primary: theme[currentTheme].primary,
            navSecondary: theme[currentTheme].navSecondary,
            paginationSecondary: theme[currentTheme].paginationSecondary,
          };

    const currentThemeColorsFilters =
      !currentTheme || !theme[currentTheme]
        ? {
            color: theme.colors.primary,
            activeBgColor: theme.colors.primary,
            sliderTrackColor: theme.colors.secondary,
            trackBorderColor: theme.colors.secondary,
          }
        : {
            color: theme[currentTheme].primary,
            activeBgColor: theme[currentTheme].primary,
            sliderTrackColor: theme[currentTheme].primary,
            trackBorderColor: theme[currentTheme].primary,
          };

    return (
      <React.Fragment>
        <GlobalStyles />
        <Helmet />
        <LogoMicrodata
          url={process.env.REACT_APP_BASE_URL as string}
          logo={`${process.env
            .REACT_APP_BASE_URL as string}/images/zemplin/logo.svg`}
        />

        <ThemeProvider
          theme={{
            ...theme,
            colors: {
              ...theme.colors,
              ...currentThemeColors,
            },
            categoryFilter: {
              ...theme.categoryFilter,
              ...currentThemeColorsFilters,
            },
          }}
        >
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                themeColor: currentThemeColors.primary,
              }}
            />
          )}
          {/*user && user.b2b && token && (
            <TopInfoBanner message={topText ? topText : ''} />
          )*/}
          <ContainerHeader />
          {/* <Shadow /> */}
          {/*<BreadCrumbContainer />*/}
          <FillSpaceWrapper>
            {!isLoaded ? <LoaderWrapper height={300} /> : children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} />
          {dimmerVisible && <Dimmer height={null} />}
          {addToCartModal && addToCartModalProduct && (
            <AddToCartModal
              product={addToCartModalProduct}
              handleClose={() => dispatch(setAddToCartModalVisibility())}
              isRequest={addToCartModalIsRequest}
              handleButtonClick={count =>
                dispatch(addItemToCartFromModal(parseFloat(count)))
              }
              isLoggedIn={user && user.b2b ? true : false}
              user={user}
              isFromCategory={false}
            />
          )}

          {!user || !user.b2b ? <AddedToCartModal dispatch={dispatch} /> : null}
          {!isSSR() && (
            <CookieBot
              domainGroupId={domainGroupId}
              language={lang === 'cz' ? 'Cs' : 'SK'}
            />
          )}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
    addToCartModalProduct: addToCartModalProductSelector(state),
    addToCartModal: addToCartModalVisibleSelector(state),
    addToCartModalIsRequest: addToCartModalIsRequestSelector(state),
    topText: topTextSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
