import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import {
  fetchFavoriteProducts,
  wishListDataSelector,
  wishListIsFetchingSelector,
} from './myAccountSlice';
import { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { addBulkItemsToCart, addItemToCart } from '../Cart/cartSlice';
import WishList from '../../components/MyAccount/Wishlist';
import { removeFromFavorite } from '../Product/actions';

const LIMIT = 10;

interface Props {
  user: any;
  dispatch: any;
  isFetchingProducts: boolean;
  products: ThenArg<ReturnType<typeof fetchFavoriteProducts>>['data'];
  total: number;
  offset: number;
  isFetchingCart: boolean;
}

class WishListContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_WISHLIST));
      await Promise.all([await dispatch(fetchFavoriteProducts(LIMIT, 0))]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {};
  }

  public render() {
    const {
      isFetchingProducts,
      products,
      total,
      offset,
      isFetchingCart,
    } = this.props;

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Obľúbené' }} />
        <WishList
          handleOffsetChange={this.handleOffsetChange}
          isFetchingProducts={isFetchingProducts}
          products={products}
          total={total}
          limit={LIMIT}
          offset={offset}
          addToCart={this.addToCart}
          addBulkToCart={this.addBulkToCart}
          addingToCart={isFetchingCart}
          handleRemoveClick={this.handleRemoveClick}
        />
      </>
    );
  }

  public handleOffsetChange = e => {
    const { dispatch } = this.props;
    const num = parseInt(e.target.text, 10) - 1;
    const offsett = LIMIT * num;
    dispatch(fetchFavoriteProducts(LIMIT, offsett));

    window.scrollTo(0, 0);
  };

  public addToCart = async (product, good_id: number, quantity: number) => {
    const { dispatch } = this.props;
    try {
      dispatch(
        addItemToCart(
          product,
          good_id,
          product.product_id,
          quantity,
          false,
          false,
        ),
      );
    } catch (e) {}
  };

  public addBulkToCart = async products => {
    const { dispatch } = this.props;
    try {
      dispatch(addBulkItemsToCart(products));
    } catch (e) {}
  };
  public handleRemoveClick = async (product_id: number, good_id: number) => {
    const { dispatch, offset } = this.props;
    try {
      await dispatch(removeFromFavorite(product_id, good_id));
      await dispatch(fetchFavoriteProducts(LIMIT, offset));
    } catch (e) {}
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingProducts: wishListIsFetchingSelector(state),
    products: wishListDataSelector(state),
    total: state.myAccount.userInfo.wishList.total,
    offset: state.myAccount.userInfo.wishList.offset,
    isFetchingCart: state.cart.isFetching,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'WishListContainer' })(WishListContainer),
);
