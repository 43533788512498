import * as React from 'react';
import { __, __r } from 'react-i18n/lib';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  langSelector,
  searchResultsCategoriesSelector,
} from '../App/selectors';
import { WithRouterProps } from 'react-router';
import { connectSsr } from 'ssr-service';
import {
  fetchCategory,
  urlAttribsToObject,
  categoryIsFetchingSelector,
  categoryInfoSelector,
  categoryFilterDataSelector,
  categoryProductsSelector,
} from '../Category/categorySlice';
import { titleSelector, tokenSelector } from '../Category/selectors';
import SearchResults from '../../components/Search/SearchResults';
import styled from '../../theme/styled-components';
import { FlexCol, FlexRowCenterVertical, prop } from 'eshop-defaults';
import { rem } from 'polished';
import { cartDataSelector } from '../Cart/cartSlice';
import { loadSearchTermCategories } from '../App/actions';
import { categoryEntitiesSelector } from '../Header/selectors';
import { productCategoriesSelector } from '../Header/selectors';
import { debounce } from 'lodash';
import { addToFavorite, removeFromFavorite } from '../Product/actions';

export interface CategoryProps {
  location: any;
  category: any;
  parentProps: any;
  loading: boolean;
  dispatch: any;
  lang: string;
  defaultTitle: string;
  token: string;
  isLoading: boolean;
  limit: number;
  sort: string;
  sortDir: string;
  isFetching: boolean;
  offset: number;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  searchedCategories: any[];
  specialCategoryProducts: ThenArg<typeof API.searchProducts>;
  specialCategoryData: ThenArg<typeof API.loadCategory>;
  isFetchingSpecial: boolean;
  cart: ThenArg<typeof API.getCart> | null;
  treeById: any;
  allCategories: ThenArg<typeof API.loadTree>;
}

class SearchResultsNew extends React.Component<
  CategoryProps & WithRouterProps
> {
  public static getSearchQuery = (
    props: CategoryProps & WithRouterProps,
  ): string => props.location.query.q;

  public static async getInitialProps(parentProps) {
    try {
      const {
        dispatch,
        location: { query },
      } = parentProps;
      console.log('inital fetc');
      const searchTerm = SearchResultsNew.getSearchQuery(parentProps);
      dispatch(loadSearchTermCategories(searchTerm));
      await Promise.all([
        dispatch(fetchCategory(-1, urlAttribsToObject(query))),
      ]);

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public componentDidUpdate = async prevProps => {
    const { props } = this;
    const prevQuery = prop(prevProps, 'location.query');
    const currentQuery = prop(props, 'location.query');
    if (
      (prevQuery !== '' || currentQuery !== '') &&
      currentQuery !== prevQuery
    ) {
      try {
        const {
          dispatch,
          location: { query },
        } = props;
        const searchTerm = SearchResultsNew.getSearchQuery(props);
        dispatch(loadSearchTermCategories(searchTerm));
        await Promise.all([
          dispatch(fetchCategory(-1, urlAttribsToObject(query))),
        ]);

        return;
      } catch (exp) {
        return {
          isError: true,
        };
      }
    }
    return;
  };

  public addToWishList = debounce(async (productId, goodId, add) => {
    const { dispatch, user } = this.props;
    if (!user) {
      window.location.href = __r('routes:prihlasenie', '/prihlasenie');
    }
    if (add) {
      await Promise.all([dispatch(addToFavorite(productId, goodId))]);
    } else {
      await Promise.all([dispatch(removeFromFavorite(productId, goodId))]);
    }
  }, 300);

  public render() {
    const {
      dispatch,
      isFetching,
      products,
      data,
      user,
      filterData,
      searchedCategories,
      cart,
      treeById,
      allCategories,
    } = this.props;
    const catName = '';

    return (
      <SearchResults
        dispatch={dispatch}
        isFetching={isFetching}
        products={products}
        data={data}
        user={user}
        catName={catName}
        filterData={filterData}
        categories={allCategories}
        renderHeader={this.renderHeader}
        searchTerm={SearchResultsNew.getSearchQuery(this.props)}
        cart={cart}
        treeById={treeById}
        addToWishList={this.addToWishList}
      />
    );
  }

  public renderHeader(products, searchTerm) {
    let foundText = 'Našlo sa ';
    let resultsText = ' výsledkov';
    if (products) {
      foundText =
        products.total === 1
          ? 'Našiel sa '
          : products.total < 5 && products.total > 0
          ? 'Našli sa '
          : 'Našlo sa ';
      resultsText =
        products.total === 1
          ? ' výsledok'
          : products.total < 5 && products.total > 0
          ? ' výsledky'
          : ' výsledkov';
    }

    return (
      <Header>
        <SearchedTermWrapper>
          {__('Hľadaný výraz')}{' '}
          <HighlitedSearchTerm>"{searchTerm}"</HighlitedSearchTerm>
        </SearchedTermWrapper>
        <ResultsTotal>
          {__(foundText)} <b>{products && products.total}</b>
          {__(resultsText)}
        </ResultsTotal>
      </Header>
    );
  }

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };
}

const Header = styled(FlexCol)`
  padding-bottom: ${rem(32)};
  /* border-bottom: ${rem(1)} solid #e8e8e8; */

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  font-weight: bold;
  font-size: ${rem(30)};
  font-family: ${({ theme }) => theme.font.secondary};
  line-height: ${rem(38)};
  margin-bottom: ${rem(24)};
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediab.s450`
    font-weight: 500;
    margin-bottom: ${rem(16)};
    display: block;
    font-size: ${rem(24)};
    line-height: ${rem(28)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: 0;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.tertiary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(0)};
     margin-bottom: ${rem(32)};
     font-size: ${rem(16)};
  `}
`;

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    token: tokenSelector(state),
    isFetching: categoryIsFetchingSelector(state),
    products: categoryProductsSelector(state),
    data: categoryInfoSelector(state),
    user: state.auth.user,
    filterData: categoryFilterDataSelector(state),
    categories: searchResultsCategoriesSelector(state),
    cart: cartDataSelector(state),
    treeById: categoryEntitiesSelector(state),
    allCategories: productCategoriesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SearchResultsNew' })(SearchResultsNew),
);
