import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { __r } from 'react-i18n';
import API from './services/API';
import { cartRoutes } from './utilities/cart';

// containers
import Home from './containers/Home/Home';
import App from './containers/App/App';
import loadable from '@loadable/component';
import Product from './containers/Product/Product';
import Category from './containers/Category/Category';
import Search from './containers/Search/SearchResultsNew';
import LoginPage from './containers/Auth/LoginPage';
import RegisterPage from './containers/Auth/RegisterPage';
import ForgotPassword from './containers/Auth/ForgotPassword';
import Cart from './containers/Cart/Cart';
import CartFirstStep from './containers/Cart/CartFirstStep';
import CartSecondStep from './containers/Cart/CartSecondStep';
import CartThirdStep from './containers/Cart/CartThirdStep';
import CMSArticles from './containers/CMS/CMSArticles';
import UrlMapper from './containers/UrlMapper/UrlMapper';
import FinishedOrder from './containers/Cart/FinishedOrder';
import NewPassword from './containers/MyAccount/NewPassword';
import MyAccount from './containers/MyAccount/MyAccount';
import MyOrders from './containers/MyAccount/MyOrders';
import MyOrdersDetail from './containers/MyAccount/MyOrdersDetail';
import MyDeliveryAddresses from './containers/MyAccount/MyDeliveryAddresses';
import MyInfo from './containers/MyAccount/MyInfo';
import ChangePassword from './containers/MyAccount/ChangePassword';
import Activation from './containers/MyAccount/Activation';
import WishList from './containers/MyAccount/WishList';

// const LoadableProduct = loadable(() => import('./containers/Product/Product'));
// const LoadableCategory = loadable(() =>
//   import('./containers/Category/Category'),
// );
// const LoadableSearch = loadable(() =>
//   import('./containers/Search/SearchResultsNew'),
// );
// const LoadableLoginPage = loadable(() => import('./containers/Auth/LoginPage'));
// const LoadableRegisterPage = loadable(() =>
//   import('./containers/Auth/RegisterPage'),
// );
// const LoadableForgotPassword = loadable(() =>
//   import('./containers/Auth/ForgotPassword'),
// );
// const LoadableCart = loadable(() => import('./containers/Cart/Cart'));
// const LoadableCartFirstStep = loadable(() =>
//   import('./containers/Cart/CartFirstStep'),
// );
// const LoadableCartSecondStep = loadable(() =>
//   import('./containers/Cart/CartSecondStep'),
// );
// const LoadableCartThirdStep = loadable(() =>
//   import('./containers/Cart/CartThirdStep'),
// );

// const LoadableCMSArticles = loadable(() =>
//   import('./containers/CMS/CMSArticles'),
// );
// const LoadableUrlMapper = loadable(() =>
//   import('./containers/UrlMapper/UrlMapper'),
// );
// const LoadableFinishedOrder = loadable(() =>
//   import('./containers/Cart/FinishedOrder'),
// );
// const LoadableNewPassword = loadable(() =>
//   import('./containers/MyAccount/NewPassword'),
// );
// const LoadableHome = loadable(() => import('./containers/Home/Home'));
// const LoadableMyOrders = loadable(() =>
//   import('./containers/MyAccount/MyOrders'),
// );
// const LoadableMyOrdersDetail = loadable(() =>
//   import('./containers/MyAccount/MyOrdersDetail'),
// );
// const LoadableChangePassword = loadable(() =>
//   import('./containers/MyAccount/ChangePassword'),
// );
// const LoadableMyDeliveryAddresses = loadable(() =>
//   import('./containers/MyAccount/MyDeliveryAddresses'),
// );
// const LoadableMyInfo = loadable(() => import('./containers/MyAccount/MyInfo'));
// const LoadableMyAccount = loadable(() =>
//   import('./containers/MyAccount/MyAccount'),
// );
// const LoadableActivation = loadable(() =>
//   import('./containers/MyAccount/Activation'),
// );

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const renderActivation = props => {
  return <Activation API={API} params={props.params} />;
};

const Routes = (props: any) => {
  return (
    <Router onUpdate={scrollToTop} history={props.history}>
      <Route path="/" component={App}>
        <Route
          path={__r('routes:vyhladavanie', '/vyhladavanie')}
          component={Search}
        />
        <Route
          path={`${__r(
            'routes:produkt',
            '/produkt',
          )}/:product_id/:product_url(/**)`}
          component={Product}
        />
        {[
          __r('routes:novinky', '/novinky'),
          __r('routes:akcie', '/akcie'),
          __r('routes:vypredaj', '/vypredaj'),
          `${__r(
            'routes:kategoria',
            '/kategoria',
          )}/:category_id/:category_url(/**)`,
        ].map((path, index) => (
          <Route path={path} component={Category} key={index} />
        ))}

        <Route
          path={__r('routes:prihlasenie', '/prihlasenie')}
          component={LoginPage}
        />
        <Route
          path={__r('routes:registracia', '/registracia')}
          component={RegisterPage}
        />
        <Route
          path={__r('routes:zabudnute-heslo', '/zabudnute-heslo')}
          component={ForgotPassword}
        />
        <Route path={__r('routes:moj-ucet', '/moj-ucet')} component={MyAccount}>
          <Route
            path={__r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky')}
            component={MyOrders}
          />
          <Route
            path={`${__r(
              'routes:moj-ucet/objednavky',
              '/moj-ucet/objednavky',
            )}/:id`}
            component={MyOrdersDetail}
          />
          <Route
            path={__r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje')}
            component={MyInfo}
          />
          <Route
            path={__r(
              'routes:moj-ucet/moje-dodacie-adresy',
              '/moj-ucet/moje-dodacie-adresy',
            )}
            component={MyDeliveryAddresses}
          />
          <Route
            path={__r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')}
            component={WishList}
          />
          <Route
            path={__r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla')}
            component={ChangePassword}
          />
          <IndexRoute component={MyOrders} />
        </Route>
        <Route
          path={__r('routes:magazin', '/magazin')}
          component={CMSArticles}
        />
        <Route path={__r(cartRoutes.INDEX, '/kosik')} component={Cart}>
          <Route
            path={__r(cartRoutes.SECOND_STEP, '/kosik/osobne-udaje')}
            component={CartSecondStep}
          />
          <Route
            path={__r(cartRoutes.THIRD_STEP, '/kosik/dodanie-a-platba')}
            component={CartThirdStep}
          />
          <IndexRoute component={CartFirstStep} />
        </Route>
        <Route
          path={`${__r(
            'routes:dokoncena-objednavka',
            '/dokoncena-objednavka',
          )}/:orderPublicId`}
          component={FinishedOrder}
        />

        <Route
          path={__r('routes:aktuality', '/aktuality')}
          component={CMSArticles}
        />

        <Route
          path={`${__r('routes:nove-heslo', '/nove-heslo')}/:token`}
          component={NewPassword}
        />

        <Route path={'/activation/:code'} component={renderActivation} />
        <Route path="**" component={UrlMapper} />
        <IndexRoute component={Home} />
      </Route>
    </Router>
  );
};

export default Routes;
