import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Link } from 'react-router';
import { ButtonLink } from 'eshop-defaults/lib/components/Sanitaske/General/Button';
import {
  SanitasButtonPrimary,
  SanitasIconType as IconType,
  SanitasSvgIcon as SvgIcon,
} from 'eshop-defaults';
import { resolveCurrencySignFromCurrency } from '../../configureTrans';

interface Props {
  numberOfProducts: number;
  totalPrice: number;
  currency: string;
  cartRoute: string;
}

function HeaderCart({
  numberOfProducts,
  totalPrice,
  currency,
  cartRoute,
}: Props) {
  currency = resolveCurrencySignFromCurrency(currency);
  return (
    <CartLink to={cartRoute}>
      <ButtonContent>
        <SvgIcon
          icon={IconType.newCart}
          alt="Prejsť na košík"
          width={24}
          height={24}
          marginRight={12}
          fill={'white'}
          cursor="pointer"
        />
        <TotalPrice>{`${totalPrice ? totalPrice.toFixed(2) : 0} ${currency ||
          '€'}`}</TotalPrice>
      </ButtonContent>
      <NumOfProducts>{numberOfProducts}</NumOfProducts>
    </CartLink>
  );
}

const CartLink = styled(ButtonLink)`
  text-decoration: none;
  font-size: ${rem(16)};
  line-height: ${rem(18)};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  min-width: ${rem(180)};
  max-width: ${rem(194)};

  ${({ theme }) => theme.media(0, 'sm')} {
    background-color: transparent;
    width: initial;
    height: initial;
    min-width: max-content;
  }
`;

const NumOfProducts = styled.span`
  min-width: ${rem(48)};
  max-width: ${rem(48)};
  display: inline-block;
  text-align: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #29b6a9;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: ${rem(5.75)};
    height: ${rem(5.75)};
    position: absolute;
    left: ${rem(-(5.75 / 2))};
    top: 50%;
    transform: translateY(-50%) rotate(50deg);
    background-color: inherit;
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    display: none;
  }
`;

const TotalPrice = styled.span`
  display: inline-block;
  font-size: ${rem(16)};
  white-space: nowrap;
  position: relative;
  top: ${rem(2)};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  ${({ theme }) => theme.media(0, 'sm')} {
    display: none;
  }
`;

const ButtonContent = styled.div`
  flex: 1 1 auto;
  padding: ${rem(11)} ${rem(20)} ${rem(13)} ${rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media(0, 'sm')} {
    padding: 0;

    svg {
      fill: ${({ theme }) => theme.colors.tertiary} !important;
      margin-right: 0;
    }
  }
`;

export default HeaderCart;
