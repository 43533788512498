import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import API from '../../services/API';
import { __ } from 'react-i18n';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import {
  ErrorAlert,
  SanitasButtonPrimary,
  SanitasFormInput,
} from 'eshop-defaults';
import { Loader } from 'eshop-defaults/lib';
import { LogoutButton } from './MyAccount';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import BreadCrumb from '../BreadCrumb/BreadCrumb';

interface NewPasswordBoxPropsIO {
  token: string;
  onOk: () => void;
  onSignUpLinkClick: () => void;
  dispatch: (a: any) => void;
}

interface State {
  password: string;
  error: string;
  passwordChanged: boolean;
  fetching: boolean;
}

class NewPassword extends React.Component<
  NewPasswordBoxPropsIO & WithRouterProps,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      error: '',
      passwordChanged: false,
      fetching: false,
    };
  }

  public async componentDidMount() {
    this.props.dispatch(
      setBreadCrumbPath(BreadCrumbType.CHANGE_PASSWORD, {
        token: this.props.params.token,
      }),
    );
  }

  public onSetPasswordClick = async () => {
    try {
      this.setState({ fetching: true });
      await API.newPassword(
        {},
        { new_password: this.state.password },
        { xLostPasswordToken: this.props.params.token },
      );
      this.setState({ passwordChanged: true, fetching: false });
    } catch (exp) {
      this.setState({ error: exp.details.description, fetching: false });
    }
  };

  public onPasswordChanged = e => {
    this.setState({ password: e.target.value });
  };

  public onOk = () => {
    this.setState({ passwordChanged: false, error: '' });
    this.props.router.push('/');
  };

  public render() {
    const { password, error, fetching } = this.state;
    return (
      <Loader loading={fetching}>
        <BreadCrumb withWrapper={true} />
        <Wrapper>
          {!this.state.passwordChanged ? (
            <div>
              {error ? <ErrorAlert message={error} /> : ''}
              <Title>{__('Zmena hesla')}</Title>
              <EmailWrapper>
                <SanitasFormInput
                  type="password"
                  placeholder={__('Nové heslo')}
                  onChange={this.onPasswordChanged}
                  value={password}
                  name="new-password"
                />
              </EmailWrapper>

              <AuthButtonWrapper>
                <ChangePasswordButton onClick={this.onSetPasswordClick}>
                  {__('Zmeniť heslo')}{' '}
                </ChangePasswordButton>
              </AuthButtonWrapper>
            </div>
          ) : (
            <div>
              <SuccessTextWrapper>
                {__('Heslo bolo úspešne aktualizované')}
              </SuccessTextWrapper>
              <AuthButtonWrapper>
                <ChangePasswordButton onClick={this.onOk}>
                  {' '}
                  OK{' '}
                </ChangePasswordButton>
              </AuthButtonWrapper>
            </div>
          )}
        </Wrapper>
      </Loader>
    );
  }
}

const ChangePasswordButton = styled(SanitasButtonPrimary)`
  width: 100%;
  max-width: ${rem(320)};
  margin-top: ${rem(32)};
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  padding: ${rem(25)};
  background-color: white;
  margin: ${rem(30)} auto 0;
  border-radius: ${rem(4)};
`;

const EmailWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

const AuthButtonWrapper = styled.div`
  margin-bottom: ${rem(25)};
`;

const SuccessTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(25)};
  color: ${({ theme }) => theme.colors.primary};
`;

const Title = styled.h1`
  font-size: ${rem(30)};
  text-align: center;
  font-weight: 500;
  position: relative;
  font-family: ${({ theme }) => theme.font.secondary};
  margin-bottom: ${rem(32)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediab.l925`
    font-size: ${rem(24)};
    line-height: ${rem(28)};
  `}
`;

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(withRouter(NewPassword));
