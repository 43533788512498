import * as React from 'react';
import { __, __r } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  TextP,
  FlexRow,
  FlexColCenter,
  FlexCol,
  IconImg,
  SearchForm,
  prop,
  SanitasSearchForm,
  SanitasInfoSection,
  SanitasHeaderLogo,
  SanitasMobileNavMenu as MobileNavMenu,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import { CART_ROUTES } from '../../containers/Cart/utilities';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { cartRoutes } from '../../utilities/cart';
import { SanitasNavigation } from '../Home/Navigation';
import HeaderWishlist from './HeaderWishlist';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  changeDimmerVisible: (newVal: boolean) => void;
  partialSearchCategoryProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  user: any;
  cartData: ThenArg<typeof API.getCart>;
  resetSearchTerm: any;
}

function DesktopHeader({
  user,
  contact: { phone, email },
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  changeDimmerVisible,
  items,
  partialSearchProducts,
  partialSearchProductsIsFetching,
  partialSearchCategories,
  partialSearchCategoriesIsFetching,
  cartData,
  location,
  resetSearchTerm,
  partialSearchCategoryProducts,
}: Props & WithRouterProps) {
  const logoSrc = '/images/sanitaske/logo--subtitle.svg';
  const smallLogoSrc = '/images/sanitaske/logo.svg';
  const uploadSrc = '/images/sanitaske/upload.svg';
  const { items: cartItems, currency, total_items_price } =
    (cartData as any) || {};
  const cartRoute = CART_ROUTES[prop(cartData, 'step', 1)];

  const [isMenuAllowed, setIsMenuAllowed] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [stickyHeaderShowed, setStickyHeaderShowed] = React.useState(false);

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setIsMenuVisible(false);
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 100) {
      setStickyHeaderShowed(true);
    }
    if (window.pageYOffset < 25) {
      setStickyHeaderShowed(false);
    }
  };

  React.useEffect(() => {
    const { pathname } = location;
    if (!pathname.includes(__r('routes:vyhladavanie', 'vyhladavanie'))) {
      resetSearchTerm();
    }
    if (
      pathname.includes(__r(cartRoutes.INDEX, '/kosik')) ||
      pathname.includes(__r('routes:moj-ucet', '/moj-ucet'))
    ) {
      setIsMenuAllowed(true);
    } else {
      setIsMenuAllowed(false);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, resetSearchTerm]);

  return (
    <HeaderWrapper>
      <StyledDesktopWrapper>
        {isMenuAllowed && isMenuVisible && (
          <>
            <MobileNavMenu closeMenu={closeMenu}>
              <SanitasNavigation items={items} location={location} />
            </MobileNavMenu>
            <Dimmer height={100} />
          </>
        )}
        <UpperHeader>
          <UpperHeaderWrapper>
            <LeftWrapper stickyHeaderShowed={stickyHeaderShowed}>
              <LogoWrapper>
                {isMenuAllowed && (
                  <>
                    <MenuIconWrapper>
                      <SvgIcon
                        icon={IconType.listMenu}
                        alt="Otvoriť menu"
                        cursor={'pointer'}
                        marginRight={0}
                        viewBox="2 2 20 20"
                        width={32}
                        height={32}
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={toggleMenu}
                      />
                      <MenuText>Menu</MenuText>
                    </MenuIconWrapper>
                  </>
                )}

                <SanitasHeaderLogo
                  logo={!stickyHeaderShowed ? logoSrc : smallLogoSrc}
                />
              </LogoWrapper>
              <SearchWrapper>
                <InfoWrapper isSticky={stickyHeaderShowed}>
                  <SanitasInfoSection phone={phone} email={email} />
                </InfoWrapper>
                <SanitasSearchForm
                  user={user}
                  searchResults={{
                    productsResult: partialSearchProducts,
                    categoriesResult: partialSearchCategories,
                  }}
                  redirectToSearchResultPage={redirectToSearchResultsPage}
                  searchTerm={searchTerm}
                  handleSearchTermChange={handleSearchTermChange}
                  handleFocus={handleSearchFocus}
                  hideResultViewer={hideSearchResultsViewer}
                  resultsDropdownVisible={resultsDropdownVisible}
                  partialSearchProductsIsFetching={
                    partialSearchProductsIsFetching
                  }
                  partialSearchCategoriesIsFetching={
                    partialSearchCategoriesIsFetching
                  }
                  partialSearchCategoryProducts={partialSearchCategoryProducts}
                />
              </SearchWrapper>
            </LeftWrapper>
            <RightWrapper>
              <HeaderProfileWrapper isSticky={stickyHeaderShowed}>
                <HeaderProfile />
              </HeaderProfileWrapper>
              <HeaderButtonsWrapper stickyHeaderShowed={!stickyHeaderShowed}>
                {' '}
                <HeaderWishlist stickyHeaderShowed={stickyHeaderShowed} />
                <HeaderCart
                  cartRoute={cartRoute}
                  numberOfProducts={cartItems ? cartItems.length : 0}
                  totalPrice={total_items_price ? total_items_price : 0}
                  currency={currency}
                />
              </HeaderButtonsWrapper>
            </RightWrapper>
          </UpperHeaderWrapper>
        </UpperHeader>
        {/* <BottomHeader>
          <Navigation
            user={user}
            changeDimmerVisible={changeDimmerVisible}
            items={items}
          />
          {user && user.b2b ? (
            <ImportOrderButton
              to={__r('routes:import-objednavky', '/import-objednavky')}
            >
              <IconImg
                cursor="pointer"
                src={uploadSrc}
                width={24}
                height={24}
                alt="upload-icon"
              />{' '}
              <ImportOrderTitle>{__('Import objednávky')}</ImportOrderTitle>
            </ImportOrderButton>
          ) : null}
        </BottomHeader> */}
      </StyledDesktopWrapper>
    </HeaderWrapper>
  );
}

const LeftWrapper = styled(FlexRow)`
  width: ${({ stickyHeaderShowed }) => (stickyHeaderShowed ? '70%' : '100%')};
`;

const HeaderButtonsWrapper = styled(FlexRow)`
  display: flex;
  ${({ theme, stickyHeaderShowed }) =>
    stickyHeaderShowed &&
    theme.mediab.l1150`

    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
  `}
`;

const StyledDesktopWrapper = styled(DesktopWrapper)`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const HeaderWrapper = styled(FlexCol)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow.header};
  align-items: center;
  @media print {
    display: none;
  }
`;

const UpperHeader = styled.div`
  border-bottom: ${rgba('#003463', 0.28)};
  width: 100%;
`;

const UpperHeaderWrapper = styled(FlexRow)`
  padding: ${rem(16)};
  align-items: flex-end;
  width: 100%;
  align-items: stretch;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const BottomHeader = styled(FlexRow)`
  padding: 0 ${rem(24)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
`;

const ImportOrderButton = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(19)};
  line-height: ${rem(22)};
  text-decoration: none;

  max-width: ${rem(237)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const ImportOrderTitle = styled.span`
  margin-left: ${rem(16)};
  display: flex;
  align-items: flex-end;
`;

const SearchWrapper = styled(FlexColCenter)`
  width: 100%;
  max-width: ${rem(579)};
  margin: 0 ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(460)};
  `}
`;

const RightWrapper = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: ${rem(420)};
  margin-right: 0;
  align-content: space-between;
  ${({ theme }) => theme.mediab.l1300`
    min-width: ${rem(200)};
  `}
`;

const NeedHelp = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
  justify-content: flex-start;
  width: 100%;
`;

const SmallTextP = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
`;

const SmallTextLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0;
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};

  &:hover {
    text-decoration: underline;
  }
`;

const DotDelimeter = styled.span`
  margin: 0 ${rem(12)};
  height: ${rem(2)};
  width: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

const LogoWrapper = styled(RightWrapper)`
display: inline-flex;
justify-content: flex-start;
align-items: center;
flex-wrap: nowrap;
max-width ${rem(342)};
min-width: ${rem(342)};
`;

const MenuIconWrapper = styled.div`
  margin-right: ${rem(16)};
  text-align: center;

  padding-right: ${rem(24)};
  margin-right: ${rem(24)};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: ${rem(1)};
    background-color: #e8e8e8;
    height: ${rem(82)};
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  svg {
    fill: ${({ theme }) => theme.colors.tertiary};
  }
`;

const MenuText = styled.span`
  display: block;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.tertiary};
  font-size: ${rem(10)};
  line-height: ${rem(17)};
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
`;

const InfoWrapper = styled.div<{ isSticky: boolean }>`
  display: ${props => (props.isSticky ? 'none' : 'block')};
  width: 100%;
  text-align: left;
`;

const HeaderProfileWrapper = styled.div<{ isSticky: boolean }>`
  display: ${props => (props.isSticky ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${rem(2)};
`;

export default withRouter(DesktopHeader);
