import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  SanitasTitle as Title,
  ClubWishList,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';

type Product = {
  quantity: number;
};
type Good = {
  good_id: string;
};

interface Props {
  addToCart: (product, good_id: number, quantity: number) => void;
  addBulkToCart: (products) => void;
  handleOffsetChange: (e) => void;
  handleRemoveClick: (product_id: number, good_id: number) => void;
  isFetchingProducts: boolean;
  products: any;
  total: number;
  limit: number;
  offset: number;
  addingToCart: boolean;
}

function WishList({
  addToCart,
  addBulkToCart,
  handleOffsetChange,
  handleRemoveClick,
  isFetchingProducts,
  products,
  total,
  limit,
  offset,
  addingToCart,
}: Props) {
  const addedGoodIds = new Set();
  const findUniqueGood = (goods: Good[]): Good | undefined => {
    for (let good of goods) {
      if (!addedGoodIds.has(good.good_id)) {
        addedGoodIds.add(good.good_id);
        return good;
      }
    }
    return undefined;
  };
  const editedProducts = products?.map(product => {
    const uniqueGood = findUniqueGood(product.goods);

    return { ...product, goods: uniqueGood ? [uniqueGood] : [] };
  });

  const handleAddToCart = (good_id: string, quantity) => {
    const product =
      editedProducts &&
      editedProducts.find(product =>
        product.goods.some(good => good.good_id === good_id),
      );
    if (product) {
      addToCart(product, +good_id, quantity);
    }
  };

  let newProducts: Product[] = [];

  const handleAddBulkToCart = (products: { [key: string]: number }) => {
    newProducts = [];
    Object.keys(products).forEach(good_id => {
      const product = editedProducts.find(
        product => product.good_id === +good_id,
      );
      if (product) {
        newProducts.push({ ...product, quantity: products[good_id] });
      }
    });
    if (newProducts.length > 0) {
      addBulkToCart(newProducts);
    }
  };
  return (
    <Wrapper>
      <Title marginTop={0} marginBottom={24}>
        {__('Obľúbené')}
      </Title>
      {isFetchingProducts ? (
        <ZemplinLoaderWrapper height={500} />
      ) : (
        <>
          {editedProducts && editedProducts.length === 0 ? (
            <NoProducts>{__('Nemáte žiadne obľúbené produkty')}</NoProducts>
          ) : (
            <ClubWishList
              products={editedProducts}
              addToCart={handleAddToCart}
              addBulkToCart={handleAddBulkToCart}
              total={total}
              limit={limit}
              offset={offset}
              handleOffsetChange={handleOffsetChange}
              removeProductFromWishList={handleRemoveClick}
              addingToCart={addingToCart}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  /* max-width: ${rem(1048)}; */
  margin-right: 0;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${rem(1)} solid #C2CED9;
  box-shadow: 0px ${rem(2)} ${rem(8)} #4D617C14;
  border-radius: ${rem(8)};
  padding: ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
  ${({ theme }) => theme.mediab.m760`
    max-width: ${rem(450)};
    margin: auto;
    align-items: center;
  `}
`;

const NoProducts = styled.p`
  margin-top: ${rem(24)};
  margin-bottom: ${rem(24)};
  text-align: center;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 500;
  color: #000000;
`;
export default WishList;
