import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { ButtonLink } from 'eshop-defaults/lib/components/Sanitaske/General/Button';
import {
  SanitasIconType as IconType,
  SanitasSvgIcon as SvgIcon,
} from 'eshop-defaults';
import { __, __r } from 'react-i18n';

interface Props {
  stickyHeaderShowed?: boolean;
  mobile?: any;
}

function HeaderWishlist({ stickyHeaderShowed, mobile }: Props) {
  return (
    <CartLink
      stickyHeaderShowed={stickyHeaderShowed}
      mobile={mobile}
      to={__r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')}
    >
      {' '}
      <SvgIcon
        icon={IconType.heart}
        alt="Checkbox polovične vybraný"
        width={24}
        height={24}
        cursor="pointer"
      />{' '}
      {stickyHeaderShowed ? null : <Text>{__('Obľúbené položky')}</Text>}
    </CartLink>
  );
}

const CartLink = styled(ButtonLink)`
  background-color: ${({ mobile }) =>
    mobile ? '#fff' : 'rgba(255, 78, 0, 0.08)'};
  border-radius: ${rem(4)};
  padding: ${({ stickyHeaderShowed }) =>
    stickyHeaderShowed ? `${rem(8)} ${rem(12)}` : `${rem(14)} ${rem(24)}`};
  box-shadow: ${({ mobile }) => (mobile ? 'none' : '0px 2px 8px #4d617c14')};
  font-size: ${rem(16)};
  line-height: ${rem(18)};
  letter-spacing: 0;
  color: #000000;
  text-transform: none;
  margin-right: ${rem(16)};
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 78, 0, 0.12);
  }

  ${({ theme }) => theme.mediab.l1150`
    margin-bottom:  ${({ stickyHeaderShowed }) =>
      stickyHeaderShowed ? '0' : rem(8)};
    margin-right: ${({ stickyHeaderShowed }) =>
      stickyHeaderShowed ? rem(16) : '0'};
    padding: ${rem(8)} ${rem(12)};
  `}

  ${({ theme }) => theme.mediab.l925`
    margin-bottom: 0;
    margin-right: ${rem(16)};
    padding: ${rem(8)} ${rem(12)};
  `}
`;
const Text = styled.span`
  margin-left: ${rem(8)};
  ${({ theme }) => theme.mediab.l925`
      display: none;
  `}
`;

export default HeaderWishlist;
