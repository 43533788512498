import * as React from 'react';
import Footer from '../../components/Footer/Footer';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import { langSelector, footerDataSelector } from '../App/selectors';

interface Props {
  lang: string;
  sitemap: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
}

class FooterContainer extends React.PureComponent<Props> {
  public render() {
    const { sitemap } = this.props;

    return <Footer items={sitemap} />;
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    //contactInfo: contactInfoSelector(state),
    sitemap: footerDataSelector(state),
  };
};

export default connect(mapStateToProps)(FooterContainer);
