import * as React from 'react';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  tokenSelector,
  titleSelector,
  categoryDataSelector,
  categoryProductsIsFetchingSelector,
  categoryProductsSelector as specialCategoryProductsSelector,
} from './selectors';
import { langSelector } from '../App/selectors';
import { loadCategorySpecialProducts } from './actions';
import { WithRouterProps } from 'react-router';
import Category from '../../components/Category/Category';
import SpecialCategory from '../../components/Category/SpecialCategory';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import {
  fetchCategory,
  categoryIsFetchingSelector,
  categoryInfoSelector,
  categoryProductsSelector,
  categoryFilterDataSelector,
  urlAttribsToObject,
  categoryChildrenCategoriesSelector,
} from './categorySlice';
import { cartDataSelector } from '../Cart/cartSlice';
import { productCategoriesSelector } from '../Header/selectors';
import { addToFavorite, removeFromFavorite } from '../Product/actions';
import { debounce } from 'lodash';
import { __r } from 'react-i18n';
export interface CategoryProps {
  location: any;
  category: any;
  parentProps: any;
  loading: boolean;
  dispatch: any;
  lang: string;
  defaultTitle: string;
  token: string;
  isLoading: boolean;
  limit: number;
  sort: string;
  sortDir: string;
  isFetching: boolean;
  offset: number;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  specialCategoryProducts: ThenArg<typeof API.searchProducts>;
  specialCategoryData: ThenArg<typeof API.loadCategory>;
  isFetchingSpecial: boolean;
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
}

class CategoryContainer extends React.Component<
  CategoryProps & WithRouterProps
> {
  public static async getInitialProps(parentProps) {
    try {
      const {
        dispatch,
        location,
        params: { category_id },
      } = parentProps;

      const {
        query,
        query: { offset },
        pathname,
      } = location;

      if (category_id) {
        // dispatch(loadCategory(category_id || 0));
        // await Promise.all([
        //   dispatch(loadCategoryProducts(category_id, offset)),
        // ]);

        await Promise.all([
          dispatch(fetchCategory(category_id, urlAttribsToObject(query))),
        ]);
      } else {
        const catName = pathname.includes('novinky')
          ? 'Novinky'
          : pathname.includes('akcie')
          ? 'Akcie'
          : 'Výpredaj';
        dispatch(
          setBreadCrumbPath(BreadCrumbType.CUSTOM, {
            name: catName,
            url: pathname,
          }),
        );
        // await Promise.all([
        //   dispatch(fetchCategory(category_id), urlAttribsToObject(query)),
        // ]);

        await Promise.all([
          dispatch(loadCategorySpecialProducts(pathname, offset)),
        ]);
      }

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }
  public addToWishList = debounce(async (productId, goodId, add) => {
    const { dispatch, user } = this.props;
    if (!user) {
      window.location.href = __r('routes:prihlasenie', '/prihlasenie');
    }
    if (add) {
      await Promise.all([dispatch(addToFavorite(productId, goodId))]);
    } else {
      await Promise.all([dispatch(removeFromFavorite(productId, goodId))]);
    }
  }, 300);

  public render() {
    const {
      dispatch,
      isFetching,
      products,
      data,
      user,
      location,
      filterData,
      childrenCategories,
      specialCategoryProducts,
      specialCategoryData,
      isFetchingSpecial,
      cart,
      categories,
    } = this.props;
    const {
      params: { category_id },
    } = this.props;
    const { pathname } = location;
    const catName = category_id
      ? ''
      : pathname.includes('novinky')
      ? 'Novinky'
      : pathname.includes('akcie')
      ? 'Akcie'
      : 'Výpredaj';

    if (catName) {
      return (
        <SpecialCategory
          dispatch={dispatch}
          isFetching={isFetchingSpecial}
          products={specialCategoryProducts}
          data={specialCategoryData}
          user={user}
          catName={catName}
          query={this.getPaginationQuery()}
          pathname={pathname}
          cart={cart}
        />
      );
    }

    return (
      <Category
        dispatch={dispatch}
        isFetching={isFetching}
        products={products}
        data={data}
        user={user}
        catName={catName}
        filterData={filterData}
        childrenCategories={childrenCategories}
        cart={cart}
        categories={categories}
        addToWishList={this.addToWishList}
      />
    );
  }

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    token: tokenSelector(state),
    isFetchingSpecial: categoryProductsIsFetchingSelector(state),
    specialCategoryProducts: specialCategoryProductsSelector(state),
    specialCategoryData: categoryDataSelector(state),
    isFetching: categoryIsFetchingSelector(state),
    products: categoryProductsSelector(state),
    data: categoryInfoSelector(state),
    user: state.auth.user,
    filterData: categoryFilterDataSelector(state),
    childrenCategories: categoryChildrenCategoriesSelector(state),
    cart: cartDataSelector(state),
    categories: productCategoriesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CategoryContainer' })(CategoryContainer),
);
