import * as React from 'react';
import { __ } from 'react-i18n';
import styled, { withTheme } from 'styled-components';
import { rem, rgba } from 'polished';
import {
  FlexRowCenter,
  SanitasProductList as ProductList,
  SanitasNavigation as Navigation,
  SanitasContactSection as ContactSection,
  SanitasSvgIcon as SvgIcon,
  SanitasIconType as IconType,
  SanitasLoaderWrapper as LoaderWrapper,
  Banner,
  SanitasImageGallery as ImageGallery,
  SanitasSlider,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import {
  prop,
  getContactInfoBasicData,
  getContactInfoOtherData,
  getCompanyInfoData,
} from '../../utilities';
import { getImagePath } from '../../utilities/product';
import Modal from '../_helpers/Default/Modal';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import SideMenu from './SideMenu';
import { css } from '../../theme/styled-components';
import { SanitasNavigation } from './Navigation';

interface Props {
  dispatch: any;
  theme: any;
  categories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  user: any;
  firstCategoryId: number;
  adBanner: any;
  basicData: ThenArg<typeof API.loadSitemap>;
  secondData: ThenArg<typeof API.loadSitemap>;
  homeCompanyInfo: any[];
  addToWishList: (product_id, good_id, add) => void;
}

function Home(props: Props) {
  const {
    dispatch,
    categories,
    productCategoriesIsFetching,
    addToWishList,
  } = props;

  const [imageModalVisible, setImageModalVisible] = React.useState(false);
  const [modalCurrentImage, setModalCurrentImage] = React.useState(0);

  const mapSrc = '/images/sanitaske/map.webp';
  let images: any = [];
  let salesProducts = false;

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.NONE));
  }, [dispatch]);

  const renderProducts = () => {
    const { newProducts, saleProducts } = props;

    const isFetchingSaleProducts = prop(saleProducts, 'isFetching');
    const isFetchingNewProducts = prop(newProducts, 'isFetching');

    if (
      productCategoriesIsFetching ||
      (!isFetchingSaleProducts &&
        !isFetchingNewProducts &&
        !newProducts.productsData &&
        !saleProducts.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const newProductsData = prop(newProducts, 'productsData', {});
    const saleProductsData = prop(saleProducts, 'productsData', {});

    const newProductsShow: ThenArg<
      typeof API.searchProducts
    >['products'] = newProductsData
      ? prop(newProductsData, 'products', [])
      : [];

    const saleProductsShow: ThenArg<
      typeof API.searchProducts
    >['products'] = saleProductsData
      ? prop(saleProductsData, 'products', [])
      : [];

    const allProducts = [...newProductsShow, ...saleProductsShow];

    if (allProducts.length === 0) {
      salesProducts = false;
      return null;
    } else {
      salesProducts = true;
    }

    return (
      <React.Fragment>
        <HomepageTitle>{__('Novinky a akcie')}</HomepageTitle>
        <ListWrapper>
          <ProductList
            isFetching={isFetchingNewProducts || isFetchingSaleProducts}
            products={allProducts}
            visible={true}
            isElasticCategory={false}
            addToWishList={addToWishList}
            showWishList={true}
          />
        </ListWrapper>
      </React.Fragment>
    );
  };

  const renderCompanyInfo = () => {
    const { homeCompanyInfo } = props;
    const { output } = getCompanyInfoData(homeCompanyInfo);

    return (
      <CompanyInfoWrapper>
        {prop(output, '0') ? (
          <UpperPart hasSalesProducts={salesProducts}>
            {<MainUpperText dangerouslySetInnerHTML={{ __html: output[0] }} />}
          </UpperPart>
        ) : null}
        <PartsCover>
          {prop(output, '1') ? (
            <LowerPartLeft>
              <SvgIcon
                width={32}
                height={32}
                viewBox={'0 0 32 32'}
                marginRight={24}
                icon={IconType.healing}
                fill={'#3962A8'}
              />
              <div dangerouslySetInnerHTML={{ __html: output[1] }} />
            </LowerPartLeft>
          ) : null}
          {prop(output, '2') ? (
            <LowerPartRight>
              <SvgIcon
                width={32}
                height={32}
                viewBox={'0 0 32 32'}
                marginRight={24}
                icon={IconType.star}
                fill={'#2CC5B7'}
              />
              <div dangerouslySetInnerHTML={{ __html: output[2] }} />
              {/* {output[2]} */}
            </LowerPartRight>
          ) : null}
        </PartsCover>
      </CompanyInfoWrapper>
    );
  };

  const renderContactSection = () => {
    const { basicData, secondData } = props;

    const {
      title,
      address,
      openingHours,
      email,
      phone,
      mobilePhone,
      photogallery,
    } = getContactInfoBasicData(basicData);
    const {
      title: secondTitle,
      address: secondAddress,
      ico,
      dic,
      icdph,
    } = getContactInfoOtherData(secondData);

    images = photogallery
      ? photogallery
          .filter(a => a)
          .map(doc => {
            const img = getImagePath(doc.document_path, {
              width: 800,
              height: 600,
            });
            const thumbImg = getImagePath(doc.document_path, {
              width: 70,
              height: 47,
            });
            return {
              original: img,
              thumbnail: thumbImg,
              name: '',
            };
          })
      : [];

    return (
      <ContactSection
        description={title ? title : ''}
        descriptionBilling={secondTitle ? secondTitle : ''}
        address={address ? address : ''}
        addressBilling={secondAddress ? secondAddress : ''}
        openeningHours={openingHours ? openingHours : ''}
        phone={phone}
        email={email}
        mobilePhone={mobilePhone}
        ico={ico}
        dic={dic}
        icDPH={icdph}
        mapSrc={mapSrc}
        images={images}
        openModal={openModal}
      />
    );
  };

  const closeImageModal = () => {
    setImageModalVisible(false);
  };

  const setCurrentImage = c => {
    setModalCurrentImage(c);
  };

  const openModal = (index: number = 0) => {
    setImageModalVisible(true);
    setModalCurrentImage(index);
  };

  return (
    <>
      {/*<Shadow />*/}
      <MainWrapper className="container container--wide">
        <MetaTags tags={{ title: __('Domov') }} />
        <LeftWrapper>
          <SanitasNavigation items={categories} />
        </LeftWrapper>
        <RightWrapper>
          {props.adBanner && (
            <SanitasSlider
              imagePath={`${process.env.REACT_APP_API_BASE_URL}/image?query=`}
              items={props.adBanner}
              user={null}
              useB2BBanners={false}
            />
          )}
          {renderProducts()}
        </RightWrapper>
        <BottomWrapper>
          {renderCompanyInfo()}
          {renderContactSection()}
        </BottomWrapper>
        {imageModalVisible && images && images.length > 0 && (
          <Modal size="big" showX={false} closeModal={closeImageModal}>
            <ImageGallery
              closeModal={closeImageModal}
              setCurrentImage={setCurrentImage}
              items={images}
              currentIndex={modalCurrentImage}
              isVertical={false}
            />
          </Modal>
        )}
      </MainWrapper>
    </>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  /* TODO change */
  margin-top: ${rem(24)} !important;
  padding-left: ${rem(16)} !important;
  padding-right: ${rem(16)} !important;

  ${({ theme }) => theme.mediab.l925`
     margin-top: 0 !important;
  `}

  ${({ theme }) => theme.mediab.s450`
    //  padding: 0 !important;
  `}
`;

const Shadow = styled.div`
  width: 100%;
  height: ${rem(80)};
  position: absolute;
  background: transparent linear-gradient(180deg, #3962a814 0%, #ffffff00 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

const LeftWrapper = styled.div`
  float: left;
  /* width: 24%; */
  padding-bottom: ${rem(56)};

  /* ${({ theme }) => theme.mediab.l1400`
    margin-right: ${rem(16)};
  `} */

  ${({ theme }) => theme.mediab.l925`
     display: none;
  `}
`;

const RightWrapper = styled.div`
  overflow: hidden;

  float: right;
  /* width: 76%; */
  /* max-width: ${({ theme }) => `${theme.grid.container.huge}px`}; */
  max-width: calc(100% - 376px);
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    margin: 0 auto;
    float: none;
    max-width: 100%;
  `}
`;

const BottomWrapper = styled.div`
  float: right;
  /* width: 76%; */
  /* max-width: ${({ theme }) => `${theme.grid.container.huge}px`}; */
  max-width: calc(100% - 376px);
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    margin: 0 auto;
    float: none;
    max-width: 100%;
  `}
`;

const ListWrapper = styled.div`
  ${({ theme }) => theme.mediab.l925`
    margin: 0 auto;
  `}
`;

const HomepageTitle = styled.div`
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    font-size: ${rem(24)};
    line-height: ${rem(28)};
    width: 90%;
    margin: ${rem(32)} auto;
  `}
`;

const CompanyInfoWrapper = styled.div`
  width: 100%;
  padding-bottom: ${rem(56)};
  border-bottom: ${({ theme }) => theme.borders.primary};

  ${({ theme }) => theme.mediab.s450`
    padding-bottom: ${rem(48)};
  `}
`;
const UpperPart = styled.div<{ hasSalesProducts: boolean }>`
  padding-top: ${rem(56)};
  padding-bottom: ${rem(32)};
  width: 100%;
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  color: ${({ theme }) => theme.colors.black};
  text-align: justify;
  font-family: ${({ theme }) => theme.font.primary};
  padding-top: ${props =>
    props.hasSalesProducts ? `${rem(48)}` : `${rem(0)}`};

  ${({ theme, hasSalesProducts }) => theme.mediab.m580`
    padding-top: ${hasSalesProducts ? `${rem(48)}` : `${rem(12)}`} ;
  `}
`;

const MainUpperText = styled.div`
  & * {
    font-size: ${rem(14)};
    font-family: ${({ theme }) => theme.font.primary};
    background: transparent;
  }
`;

const PartsCover = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ${({ theme }) => theme.mediab.m580`
    display: block;
  `}
`;

const lowerPartLikeCss = css`
  min-height: ${rem(70)};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  text-align: left;
  padding: 0 ${rem(32)};
  overflow: hidden;
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};
  font-family: ${({ theme }) => theme.font.primary};
  max-width: ${rem(508)};
  flex: 0 0 calc(50% - 1rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${rem(4)};

  svg {
    flex: 0 0 32px;
  }

  ${({ theme }) => theme.mediab.l1300`
    flex-basis: calc(50% - 0.5rem);
  `}

  ${({ theme }) => theme.mediab.m580`
    max-width: initial;
    width: 100%;
    flex-basis: auto;
    padding: ${rem(16)} ${rem(24)};
  `}
`;

const LowerPartLeft = styled(FlexRowCenter)`
  ${lowerPartLikeCss}
  float: left;
  background-color: ${({ theme }) => rgba(theme.colors.tertiary, 0.08)};
  color: ${({ theme }) => theme.colors.secondary};
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l1300`
    margin-right: ${rem(8)};
  `}

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
    margin-right: ${rem(0)};
    width: 100%;
  `}
`;

const LowerPartRight = styled(FlexRowCenter)`
  ${lowerPartLikeCss}
  background-color: ${({ theme }) => rgba(theme.colors.success, 0.08)};
  color: ${({ theme }) => theme.colors.success};
  margin-left: ${rem(16)};

  ${({ theme }) => theme.mediab.l1300`
    margin-left: ${rem(8)};
  `}

  ${({ theme }) => theme.mediab.m580`
    margin-left: ${rem(0)};
  `}

  svg {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

export default withTheme(Home);
